import React, { ReactElement, useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cartContext";
import { CalculateTotal, FormatMoney } from "../../helpers";
import { useNavigate } from "react-router";
import { colours } from "../../styles/colours";

import { ApiService } from "../../services/api.service";
import {
  FormatDate,
  CalculateDiscount,
  FormatGeneralPrice,
} from "../../helpers";
import { AuthContext } from "../../context/authContext";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ButtonLoaderSmall from "../ButtonLoaderSmall";

function Basket(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const englandData = [
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 4, name: "4" },
    { value: 5, name: "5" },
    { value: 6, name: "6" },
    { value: 7, name: "7" },
    { value: 8, name: "8" },
    { value: 9, name: "9" },
    { value: 10, name: "10" },
    { value: 11, name: "11" },
    { value: 12, name: "12" },
    { value: 13, name: "13" },
    { value: 14, name: "14" },
    { value: 15, name: "15" },
    { value: 16, name: "16" },
    { value: 17, name: "17" },
    { value: 18, name: "18" },
    { value: 19, name: "19" },
    { value: 20, name: "20" },
    { value: 21, name: "21" },
    { value: 22, name: "22" },
    { value: 23, name: "23" },
    { value: 24, name: "24" },
    { value: 25, name: "25" },
    { value: 26, name: "26" },
    { value: 27, name: "27" },
    { value: 28, name: "28" },
    { value: 29, name: "29" },
    { value: 30, name: "30" },
    { value: 31, name: "31" },
    { value: 32, name: "32" },
    { value: 33, name: "33" },
    { value: 34, name: "34" },
    { value: 35, name: "35" },
    { value: 36, name: "36" },
    { value: 37, name: "37" },
    { value: 38, name: "38" },
    { value: 39, name: "39" },
    { value: 40, name: "40" },
    { value: 41, name: "41" },
    { value: 42, name: "42" },
    { value: 43, name: "43" },
    { value: 44, name: "44" },
    { value: 45, name: "45" },
    { value: 46, name: "46" },
    { value: 47, name: "47" },
    { value: 48, name: "48" },
    { value: 49, name: "49" },
    { value: 50, name: "50" },
    { value: 51, name: "51" },
    { value: 52, name: "52" },
    { value: 53, name: "53" },
    { value: 54, name: "54" },
    { value: 55, name: "55" },
    { value: 56, name: "56" },
    { value: 57, name: "57" },
    { value: 58, name: "58" },
    { value: 59, name: "59" },
    { value: 60, name: "60" },
    { value: 61, name: "61" },
    { value: 62, name: "62" },
    { value: 63, name: "63" },
    { value: 64, name: "64" },
    { value: 65, name: "65" },
    { value: 66, name: "66" },
    { value: 67, name: "67" },
    { value: 68, name: "68" },
    { value: 69, name: "69" },
    { value: 70, name: "70" },
    { value: 71, name: "71" },
    { value: 72, name: "72" },
    { value: 73, name: "73" },
    { value: 74, name: "74" },
    { value: 75, name: "75" },
    { value: 76, name: "76" },
    { value: 77, name: "77" },
    { value: 78, name: "78" },
    { value: 79, name: "79" },
    { value: 80, name: "80" },
    { value: 81, name: "81" },
    { value: 82, name: "82" },
    { value: 83, name: "83" },
    { value: 84, name: "84" },
    { value: 85, name: "85" },
    { value: 86, name: "86" },
    { value: 87, name: "87" },
    { value: 88, name: "88" },
    { value: 89, name: "89" },
    { value: 90, name: "90" },
    { value: 91, name: "91" },
    { value: 92, name: "92" },
    { value: 93, name: "93" },
    { value: 94, name: "94" },
    { value: 95, name: "95" },
    { value: 96, name: "96" },
    { value: 97, name: "97" },
    { value: 98, name: "98" },
    { value: 99, name: "99" },
    { value: 100, name: "100" },
  ];

  const apiService = new ApiService();

  let { cart, setCart, totalPrice, setTotalPrice, guid } =
    useContext(CartContext);

  const { userData, setUserData } = useContext(AuthContext);

  const [removingItem, setRemovingItem] = useState(false);

  const [itemToRemove, setItemToRemove] = useState(0);
  const navigate = useNavigate();

  const handleQty = (index: any, e: any) => {
    const values: any = [...cart];
    values[index]["productQuantity"] = e.target.value;
    values[index]["subTotal"] = cart[index].price! * e.target.value;
    setCart(values);

    setTotalPrice(cart.reduce((a, v) => (a = a + v.price!), 0));
    //setTotalPrice(CalculateTotal(values));

    const initialValue = 0;
    const total = cart.reduce(
      (accumulator, current) =>
        accumulator + current.price! * current.productQuantity!,
      initialValue
    );

    setTotalPrice(total);
  };

  const saveCart = async (cartData: any) => {
    const response = await apiService.PostData("api/Cart/SaveCart", cartData);
    if (response === 1) {
      navigate("/Checkout");
    }
  };

  const removeItem = (e: any, guider: any) => {
    setItemToRemove(e);
    setRemovingItem(true);

    const apiService = new ApiService();
    const removeItemFromCart = async () => {
      const response = await apiService.deleteItemFromCart(e, guider);
      setCart(response);
      setTotalPrice(CalculateTotal(response));
      setRemovingItem(false);
    };
    removeItemFromCart();
  };

  useEffect(() => {}, []);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Shop
            <span></span> Your Cart
          </div>
        </div>
      </div>
      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row">
            <h2>Your Basket</h2>

            <div className="col-12" style={{ marginTop: "25px" }}>
              {cart.length > 0 ? (
                <div>
                  <div className="table-responsive">
                    <table className="table shopping-summery text-center clean">
                      <thead>
                        <tr
                          className="main-heading"
                          style={{ background: "#eee", color: "#333" }}
                        >
                          {" "}
                          <th scope="col" style={{ width: "10%" }}>
                            Product Image
                          </th>
                          <th style={{ textAlign: "left" }} scope="col">
                            Part Code
                          </th>
                          <th scope="col">Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart &&
                          cart.map((item, index) => (
                            <tr key={item.productID}>
                              <td className="text-left image product-thumbnail">
                                <img
                                  src={
                                    window.location.origin +
                                    `/Admin/Catalogue/${item.product?.productName}.jpg`
                                  }
                                  style={{
                                    width: "100%",
                                  }}
                                  alt="text"
                                ></img>
                              </td>
                              <td
                                className="product-des product-name"
                                style={{ textAlign: "left" }}
                              >
                                <h3 className="product-name">
                                  <Link
                                    to={`/product?id=${item.product?.productID}&name=${item.product?.productName}`}
                                  >
                                    {item.product?.productName}
                                  </Link>
                                </h3>
                                <p className="font-sm">
                                  {item.subProductVariant
                                    ? item.subProductVariant
                                        .productVariantDetails + "\n"
                                    : null}{" "}
                                  {item.product?.productDetails}
                                </p>
                              </td>
                              <td className="price" data-title="Price">
                                <span>
                                  {item.productVariantID === 1
                                    ? FormatGeneralPrice(item.price!)
                                    : FormatGeneralPrice(item.price!)}
                                </span>
                              </td>
                              <td className="text-center" data-title="Stock">
                                <div className="detail-qty  m-auto">
                                  <select
                                    className="form-control"
                                    style={{ width: "55px" }}
                                    onChange={(e) => handleQty(index, e)}
                                    value={item.productQuantity}
                                  >
                                    {englandData?.map((e: any, key: any) => {
                                      return (
                                        <option
                                          key={key}
                                          value={e.value}
                                          selected={
                                            e.value ===
                                            item.productQuantity?.toString()
                                          }
                                        >
                                          {" "}
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </td>
                              <td className="text-right" data-title="Cart">
                                <span>
                                  {item.productVariantID === 1
                                    ? FormatGeneralPrice(
                                        item.price! * item.productQuantity!
                                      )
                                    : FormatGeneralPrice(
                                        item.price! * item.productQuantity!
                                      )}
                                </span>
                              </td>
                              <td className="action" data-title="Remove">
                                {removingItem &&
                                itemToRemove === item.cartID ? (
                                  <ButtonLoaderSmall></ButtonLoaderSmall>
                                ) : (
                                  <i
                                    onClick={(e) =>
                                      removeItem(item.cartID, item.guid)
                                    }
                                    className="fi-rs-trash"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                )}
                              </td>
                            </tr>
                          ))}

                        <tr>
                          <td colSpan={6} className="text-end"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="row mt-30">
                    <div className="col-lg-6 col-md-12">
                      <div className="border p-md-4 p-30 border-radius cart-totals">
                        <div className="heading_s1 mb-3">
                          <h4>Cart Total</h4>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="cart_total_label">Total</td>
                                <td className="cart_total_amount">
                                  <strong>
                                    <span className="font-xl fw-900 text-brand">
                                      {FormatGeneralPrice(totalPrice)}
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {totalPrice > 50 ? (
                          <a onClick={(e) => saveCart(cart)} className="btn">
                            <i className="fi-rs-box-alt mr-10"></i> Proceed To
                            Checkout{" "}
                          </a>
                        ) : (
                          <h4>
                            Minimum spend of {FormatGeneralPrice(50)} required
                            to check out
                          </h4>
                        )}
                        <div className="divider-text-center mt-15 mb-15">
                          <span> or</span>
                        </div>
                        <Link to="../search" className="btn-inverse">
                          <i className="fi-rs-box-alt mr-10"></i>
                          Continue Shopping{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h5>You have no items in your basket</h5>
                  <hr></hr>
                  <br></br> <br></br>
                  <Link className="btn " to="/search">
                    <i className="fi-rs-box-alt mr-10"></i> Search for products
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Basket;
