import React, { useState, createContext, useEffect } from "react";
import { ApiService } from "../services/api.service";
import { Cart } from "../types/cart";
import { CalculateTotal } from "../helpers";
import { generateUUID } from "../helpers";
import Cookie from "js-cookie";

interface AuthProviderProps {
  children: React.ReactNode;
}

const contextInitialState: {
  cart: Cart[];
  setCart: (v: Cart[]) => void;
  totalPrice: number;
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
  guid: string;
  setCartGuid: React.Dispatch<React.SetStateAction<string>>;
} = {
  cart: [],
  setCart: () => [],
  totalPrice: 0,
  setTotalPrice: () => 0,
  guid: "",
  setCartGuid: () => "",
};

export const CartContext = createContext(contextInitialState);

export const CartProvider = ({ children }: AuthProviderProps) => {
  const [cart, setCart] = useState(contextInitialState.cart);
  const [totalPrice, setTotalPrice] = useState(0);
  const [guid, setCartGuid] = useState("");

  const apiService = new ApiService();

  useEffect(() => {
    const fetchCart = async (guid: string) => {
      const cookieVal = Cookie.get("cart");
      if (cookieVal) {
        const response = await apiService.getCart(cookieVal);
        setCart(response);
        setTotalPrice(CalculateTotal(response));
      } else {
        //   //   const response = await apiService.getCart(guid);
        //   //   setCart(response);
        //   //   setTotalPrice(CalculateTotal(response));
      }
    };
    fetchCart(guid);
  }, []);

  const value = { cart, setCart, totalPrice, setTotalPrice, guid, setCartGuid };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
