import React, { ReactElement, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import styled from "@emotion/styled";
import { colours } from "../../styles/colours";
import { LoginContext } from "../../context/categoryContext";
import { CartContext } from "../../context/cartContext";
import { AuthContext } from "../../context/authContext";
import Cart from "../Cart";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { useNavigate } from "react-router";
import { Form } from "react-bootstrap";
import TesaTape from "../TesaTape";

const S = {
  Header: styled.header`
    // background-color: ${colours.MARKANDY_RED};
    color: ${colours.WHITE};
  `,
  HeaderWrapper: styled.div`
    background-color: ${colours.WHITE};
    color: ${colours.WHITE};
    padding-top: 35px;
    padding-bottom: 35px;
    img.largelogo {
      width: 100%;
      max-width: 320px;
    }
    img.smalllogo {
      width: 120px;
      max-width: 220px;
    }
  `,
  HeaderTitle: styled.span`
    color: ${colours.BLACK};
    text-align: right;
  `,
  Nav: styled.nav`
    color: ${colours.WHITE};
    a {
      font-weight: 500;
      color: ${colours.WHITE};
    }
    li {
      color: ${colours.WHITE};
      padding-right: 30px;
    }
  `,
};

function TopHeader(): ReactElement {
  const machine2200 = "DBB3346E-E714-4392-9C58-7DE43629E40D";
  const machine830 = "0A69B633-B8E2-4FFB-9DC1-4CEA6F0ACCB8";
  const machine2100 = "5D433093-C645-4367-B00C-E8F6E1755CB2";

  const machine4120 = "9BC799E3-57F7-4C1D-9F11-9F4A57CE12E7";
  const machine4150 = "E63B16F7-9647-44EC-9953-FB0396B27EA3";
  const Conveyor = "B082D42D-F038-497A-B982-D9A6B29766AC";
  const machineLP3000 = "C6874B40-4C00-4E74-B81D-FE26F8901CE9";
  const PerformanceSeries = "C5296553-35C1-42C1-999E-357E94AD4CF8";
  const Scout = "9CCF927E-1CC4-4FB1-8F90-870CF56A12FF";

  const Rotoflex = "3A6B3DBC-B663-4533-A495-5B17B09FE0EC";
  const Arpeco = "F8FB1F0C-5FD0-4AE9-9F76-CE566378F065";
  const tesatape = "3FEEDBB7-5C4B-496F-AA8B-E16E560E21E0";

  const WoodenCoreholder = "5C186C31-EBBF-4859-9A97-7F31F88E6358";
  const Cleaning = "F24391C0-C2B6-4193-B1A2-1EF98D89DDCF";
  const CuttingBlades = "7DCEE1A9-9CEC-4E1C-B822-D0BC55A1BA3A";
  const FibreWashers = "e5a7b6af-3c76-43e2-a5c5-68c89b8bd20e";
  const AniloxRoll = "D1BBE154-FA66-4C57-BCD6-5FCB9E064BB5";
  const DrBlades = "0BCA237E-E5DF-471B-8356-24731CB7C65B";
  const RollLifter = "C3A56325-FAF2-4D96-887B-1F8BD43F972D";
  const PerformanceStorage = "D4F1E3F7-AE00-4221-8E93-7E4B185E431B";
  const Teknek = "EEFF16F5-1F65-4B6B-8B5D-EF8BE2B8015A";
  const TintSleeves = "03AA2018-39CB-4DCD-9551-A889FC7D317B";

  const [show, setShow] = useState(false);
  const { cart, setCart, setTotalPrice } = useContext(CartContext);
  const cartCount = cart.length;
  const { categoryData } = useContext(LoginContext);
  const { userData, setUserData } = useContext(AuthContext);
  const [lang, setLang] = useState("");

  const [searchKeyword, setSearchKeyword] = useState("");

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    setLang(lng);
    i18n.changeLanguage(lng);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    navigate("/search?keyword=" + searchKeyword);
    setShow(false);
  };

  const logOut = async () => {
    Cookie.remove("token");
    Cookie.remove("userID");
    Cookie.remove("cart");
    setCart([]);
    setTotalPrice(0);
    setUserData({ userGuid: "" });
    navigate("/");
  };

  useEffect(() => {
    // reset(userData);
  }, []);

  return (
    <React.Fragment>
      <header
        id="MAheader"
        className="header-area header-style-1 header-height-2"
      >
        <div className="header-top header-top-ptb-1 d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-4">
                <div className="header-info">
                  <ul>
                    <li>
                      <i className="fi-rs-smartphone"></i>
                      {t("Welcome")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4">
                <div className="text-center"></div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="header-info header-info-right">
                  <ul>
                    <li>
                      <a className="language-dropdown-active" href="#">
                        <i className="fi-rs-world"></i>

                        {lang === "en"
                          ? "English"
                          : lang === "es"
                          ? "Espania"
                          : lang === "de"
                          ? "Deutsch"
                          : lang === "it"
                          ? "Italiano"
                          : lang === "fr"
                          ? "Francais"
                          : "English"}
                        <i className="fi-rs-angle-small-down"></i>
                      </a>
                      <ul className="language-dropdown">
                        <li>
                          <a onClick={() => changeLanguage("fr")}>
                            <img src="imgs/theme/flag-fr.png" alt="" />
                            Français
                          </a>
                        </li>
                        <li>
                          <a onClick={() => changeLanguage("de")}>
                            <img src="imgs/theme/flag-dt.png" alt="" />
                            Deutsch
                          </a>
                        </li>
                        <li>
                          <a onClick={() => changeLanguage("it")}>
                            <img src="imgs/theme/flag-it.png" alt="" />
                            Italiano
                          </a>
                        </li>

                        <li>
                          <a onClick={() => changeLanguage("es")}>
                            <img src="imgs/theme/flag-es.png" alt="" />
                            Espania
                          </a>
                        </li>
                        <li>
                          <a onClick={() => changeLanguage("en")}>
                            <img src="imgs/theme/flag-en.png" alt="" />
                            English
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <i className="fi-rs-user"></i>
                      <React.Fragment>
                        {userData.userGuid ? (
                          <Link to="/Dashboard">
                            {"Hi " +
                              userData!.userFirstname +
                              " " +
                              userData!.userSurname}

                            {userData.currency === 1
                              ? " ($)"
                              : userData.currency === 2
                              ? " (£)"
                              : userData.currency === 3
                              ? " (€)"
                              : null}
                          </Link>
                        ) : (
                          <Link to="/Login">Log In / Register</Link>
                        )}
                      </React.Fragment>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
          <div className="container">
            <div className="header-wrap">
              <div className="logo logo-width-1">
                <Link to="/">
                  <img
                    src={window.location.origin + `/imgs/ma_logo_invert.png`}
                    style={{ width: "100%" }}
                    alt="text"
                  ></img>
                </Link>
              </div>

              <div className="search-style-2">
                <form action="#"></form>
              </div>
              <div className="header-action-right">
                <div className="header-action-2">
                  {/* <div className="header-action-icon-2">
                    <a href="#">
                      <img
                        src={
                          window.location.origin +
                          `/imgs/theme/icons/icon-heart.svg`
                        }
                        style={{ width: "100%" }}
                        alt="text"
                        className="svgInject"
                      ></img>

                      <span className="pro-count blue">4</span>
                    </a>
                  </div> */}
                  <div className="header-action-icon-2 text-center">
                    <Link to="/basket" className="mini-cart-icon">
                      <img
                        src={window.location.origin + `/imgs/bag.png`}
                        style={{ width: "100%" }}
                        alt="text"
                        className="svgInject"
                      ></img>{" "}
                      <p
                        className="text-center small"
                        style={{ fontSize: "0.80rem", lineHeight: "12px" }}
                      >
                        BASKET
                      </p>
                      <span className="pro-count blue">{cartCount}</span>
                    </Link>
                    <Cart></Cart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-bottom header-bottom-bg-color sticky-bar">
          <div className="container">
            <div className="header-wrap header-space-between position-relative">
              <div className="logo logo-width-1 d-block d-lg-none">
                <Link to="/">
                  <img
                    src={window.location.origin + `/imgs/theme/logo_mark.png`}
                    alt="text"
                  ></img>
                </Link>
              </div>
              <div className="header-nav d-none d-lg-flex">
                <div className="main-categori-wrap d-none d-lg-block"></div>
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block">
                  <nav>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/search">Search Products</Link>
                      </li>

                      <li className="position-static">
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          Product Catalogue <i className="fi-rs-angle-down"></i>
                        </a>
                        <ul className="mega-menu">
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#">
                              Machines
                            </a>
                            <ul>
                              <li>
                                <Link to={`/search?p=${machine2200}`}>
                                  2200
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${machine2100}`}>
                                  2100
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${machine830}`}>830</Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${machineLP3000}`}>
                                  LP3000
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${machine4150}`}>
                                  4150
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${machine4120}`}>
                                  4120
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#">
                              Machines
                            </a>
                            <ul>
                              <li>
                                <Link to={`/search?p=${PerformanceSeries}`}>
                                  Performance Series
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${Scout}`}>Scout</Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${Conveyor}`}>
                                  Conveyor
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${Arpeco}`}>Arpeco</Link>
                              </li>
                              <li>
                                <Link to={`/search?p=${Rotoflex}`}>
                                  Rotoflex
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#">
                              Flexo Consumables
                            </a>

                            <ul>
                              <li>
                                <Link to={`/search?s=${tesatape}`}>
                                  Tesa Tape
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${WoodenCoreholder}`}>
                                  Wooden Coreholder Adapters
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${Cleaning}`}>
                                  Cleaning
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${CuttingBlades}`}>
                                  Cutting Blades
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${FibreWashers}`}>
                                  Fibre Washers
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="sub-mega-menu sub-mega-menu-width-34">
                            <a className="menu-title" href="#">
                              MA Miscellaneous Products
                            </a>
                            <ul>
                              <li>
                                <Link to={`/search?s=${AniloxRoll}`}>
                                  Performance Anilox Roll Range
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${DrBlades}`}>
                                  Dr Blades Range
                                </Link>{" "}
                              </li>

                              <li>
                                <Link to={`/search?s=${RollLifter}`}>
                                  MA Performance Roll Lifter Range
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${PerformanceStorage}`}>
                                  Performance Storage Range options
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${Teknek}`}>
                                  Web Cleaning Rolls Etc
                                </Link>
                              </li>
                              <li>
                                <Link to={`/search?s=${TintSleeves}`}>
                                  Range Tint Sleeves Wipers
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          key={document.location.href}
                          to={`/search?p=CCFBA11A-D877-4B96-B8A3-9E6E95E2B8A6`}
                        >
                          Consumables
                        </Link>
                      </li>
                      <li>
                        <Link to="/TesaTape">Tesa Tape</Link>
                      </li>

                      {userData.userGuid ? (
                        <li>
                          <Link to="/Manuals">Manuals</Link>
                        </li>
                      ) : null}

                      {userData.userGuid ? (
                        <li>
                          <Link to="/Dashboard">My Account</Link>
                        </li>
                      ) : null}

                      {userData.userGuid ? (
                        <li>
                          <a
                            style={{ color: colours.MARKANDY_RED }}
                            onClick={(e) => logOut()}
                          >
                            Log Out
                          </a>
                        </li>
                      ) : null}

                      {!userData.userGuid ? (
                        <li>
                          <Link to="/LogIn">Log In / Register </Link>
                        </li>
                      ) : null}

                      <li>
                        <Link to="/Contact-Us">Contact Us</Link>
                      </li>

                      {/* <li>
                        <Link to="/Administration/Dashboard">Admin</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="hotline d-none d-lg-block">
                <p>
                  {/* <i className="fi-rs-headset"></i> */}
                  {/* <span>Hotline</span> 0000 - 000 */}
                </p>
              </div>
              <p className="mobile-promotion">
                {/* Advertising <span className="text-brand">Message</span>. Here */}
              </p>
              <div className="header-action-right d-block d-lg-none">
                <div className="header-action-2">
                  {" "}
                  <div className="header-action-icon-2">
                    <Link to="/basket">
                      <img
                        alt=""
                        src="./imgs/theme/icons/icon-cart.svg"
                        style={{ color: "#fff" }}
                      />
                      <span className="pro-count white">{cartCount}</span>
                    </Link>
                  </div>
                  <div className="header-action-icon-2 d-block d-lg-none">
                    <div
                      className="burger-icon burger-icon-white"
                      onClick={() => setShow(!show)}
                    >
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="mobile-header-active mobile-header-wrapper-style"
        style={{
          opacity: !show ? "0" : "1",
          transition: "0.3s",
          right: 0,
          visibility: !show ? "hidden" : "visible",
        }}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="mobile-header-logo">
              <Link to="/">
                {" "}
                <img
                  src="https://parts.markandy.com/img/mark_logo-primary.svg"
                  style={{ width: "180px", paddingTop: "10px" }}
                  alt="text"
                ></img>
              </Link>
            </div>
            <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
              <button
                className="close-style search-close"
                onClick={() => setShow(!show)}
              >
                <i className="icon-top"></i>
                <i className="icon-bottom"></i>
              </button>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div className="mobile-search search-style-3 mobile-header-border">
              <Form>
                <input
                  type="text"
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  placeholder="Search for items…"
                ></input>
                <button onClick={onSubmit} type="submit">
                  <i className="fi-rs-search"></i>
                </button>
              </Form>
            </div>
            <div className="mobile-menu-wrap mobile-header-border">
              <div className="main-categori-wrap mobile-header-border"></div>

              <nav>
                <ul className="mobile-menu">
                  <li className="menu-item-has-children">
                    <span className="menu-expand"></span>
                    <a href="#" style={{ color: "#742529" }}>
                      Main Menu
                    </a>
                    <hr></hr>
                    {/* <ul className="dropdown">
                      {categoryData &&
                        categoryData.map((item: any) => (
                          <Link
                            style={{ display: "block" }}
                            to={`/search?p=${item.categoryGuid}`}
                          >
                            <li key={item.categoryID}>{item.categoryName}</li>
                          </Link>
                        ))}
                    </ul> */}
                    <ul className="dropdown">
                      <Link
                        onClick={() => setShow(false)}
                        style={{ display: "block" }}
                        to={`/`}
                      >
                        <li>Home</li>
                      </Link>
                      <Link
                        onClick={() => setShow(false)}
                        style={{ display: "block" }}
                        to={`/search?`}
                      >
                        <li>Search Products</li>
                      </Link>
                      <Link style={{ display: "block" }} to={`/search?`}>
                        <li>Consumables</li>
                      </Link>
                      <Link style={{ display: "block" }} to={`/search?`}>
                        <li>Tesa Tape</li>
                      </Link>

                      {userData.userGuid ? (
                        <Link style={{ display: "block" }} to="/LogIn">
                          <li>Manuals</li>
                        </Link>
                      ) : null}

                      {userData.userGuid ? (
                        <Link style={{ display: "block" }} to="/LogIn">
                          <li>My Account</li>
                        </Link>
                      ) : null}

                      {!userData.userGuid ? (
                        <Link style={{ display: "block" }} to="/LogIn">
                          <li>Log In / Register</li>
                        </Link>
                      ) : null}

                      {userData.userGuid ? (
                        <Link to="/LogIn">
                          <li>Log Out</li>
                        </Link>
                      ) : null}

                      <Link style={{ display: "block" }} to={`/search?`}>
                        <li>Contact Us</li>
                      </Link>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="mobile-header-info-wrap mobile-header-border">
              <div className="single-mobile-header-info mt-30">
                {/* <a href="#"> Our location </a> */}
              </div>
              <div className="single-mobile-header-info">
                <Link
                  className="btn btn-sm btn-fill-out btn-block hover-up"
                  to="/Login"
                >
                  Log In / Register
                </Link>
              </div>
              <div className="single-mobile-header-info">
                {/* <a href="#">(+01) - 2345 - 6789 </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TopHeader;
