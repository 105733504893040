import React, { ReactElement, useState, useEffect } from "react";
import { ApiService } from "../../../services/api.service";
import Loader from "../../Loader";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

function ProductVariant20(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const [isError, setIsError] = useState(0);

  const query = new URLSearchParams(useLocation().search);
  const id = "41504530";

  const [loading, setLoading] = useState(false);
  const [productVariants, setProductVariants] = useState([]);
  const apiService = new ApiService();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const fetchProductVariants = async (e: number) => {
    setLoading(true);
    const response = await apiService.getSubProductVariantBySuperID(2);
    setProductVariants(response);
    setLoading(false);
  };

  const onSubmit = (data: any) => {
    PostProductVariantData(productVariants);
  };

  const PostProductVariantData = async (data: any) => {
    const response = await apiService.PostData(
      "api/SubProductVariant/SaveProductVariantsBulk",
      data
    );
    if (response === 1) {
      setIsError(1);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    } else {
      setIsError(2);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    }
  };

  const handleInputChange = (index: any, event: any) => {
    const values: any = [...productVariants];

    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setProductVariants(values);
  };

  useEffect(() => {
    fetchProductVariants(Number(2));
    reset();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Product Variant Edit (20 Thou) : {id}</h1>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Price ($)</th>
                        <th scope="col">Price (€)</th>
                        <th scope="col">Price (£)</th>
                        <th scope="col">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productVariants &&
                        productVariants.map((item: any, index: number) => (
                          <tr>
                            <td>
                              <input
                                type="text"
                                defaultValue={item.productVariantPriceDollar}
                                name="productVariantPriceDollar"
                                onChange={(event: any) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                defaultValue={item.productVariantPriceEuro}
                                name="productVariantPriceEuro"
                                onChange={(event: any) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                defaultValue={item.productVariantPriceGBP}
                                name="productVariantPriceGBP"
                                onChange={(event: any) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </td>

                            <td>
                              {" "}
                              <input
                                type="text"
                                defaultValue={item.productVariantDetails}
                                name="productVariantDetails"
                                onChange={(event: any) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </td>

                            <td>{item.productVariantID}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <button
                    type="submit"
                    className="btn btn-fill-out btn-block hover-up"
                    name="login"
                  >
                    Bulk Save All Product Variants
                  </button>
                  <span
                    style={{ position: "absolute" }}
                    className={`badge badge-pill badge ${
                      isError === 2 ? "red" : isError === 1 ? "green" : ""
                    } `}
                  >
                    {isError === 2 ? "Error" : isError === 1 ? "Saved" : ""}
                  </span>
                  <br></br>
                  <br></br>
                  <br></br>
                </form>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
export default ProductVariant20;
