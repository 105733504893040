import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import Featured from "../Featured";
import Footer from "../Footer";
import Highlights from "../Highlights";
import Popular from "../Popular";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";

import "swiper/css/autoplay";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import { ApiService } from "../../services/api.service";
import { AuthContext } from "../../context/authContext";

function Home(): ReactElement {
  const { userData } = useContext(AuthContext);

  const apiService = new ApiService();

  const [mediaImages, setMediaImages] = useState<any | null>(null);

  const fetchExchange = async () => {
    const response = await apiService.getAllHomePageImages();
    setMediaImages(response);
  };

  useEffect(() => {
    fetchExchange();
  }, []);

  SwiperCore.use([Autoplay]);

  return (
    <div>
      <Container
        fluid
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          marginBottom: "-6px",
        }}
      >
        <Swiper
          modules={[Autoplay]}
          loop={true}
          speed={900}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {userData.adminContact === 1
            ? mediaImages &&
              mediaImages.map((item: any, index: number) =>
                item.countryID === 2 ? (
                  <SwiperSlide key={item.mediaImageID}>
                    <img key={item.mediaImageID}
                      src={
                        window.location.origin +
                        `/admin/homepageImages/${item.homepageImagePath}`
                      }
                      style={{ width: "100%", marginTop: "-1px" }}
                      alt="text"
                    ></img>
                  </SwiperSlide>
                ) : null
              )
            : userData.adminContact === 2
            ? mediaImages &&
              mediaImages.map((item: any, index: number) =>
                item.countryID === 1 ? (
                  <SwiperSlide key={item.mediaImageID}>
                    <img key={item.mediaImageID}
                      src={
                        window.location.origin +
                        `/admin/homepageImages/${item.homepageImagePath}`
                      }
                      style={{ width: "100%", marginTop: "-1px" }}
                      alt="text"
                    ></img>
                  </SwiperSlide>
                ) : null
              )
            : mediaImages &&
              mediaImages.map((item: any, index: number) =>
                item.countryID === 3 ? (
                  <SwiperSlide key={item.mediaImageID}>
                    <img key={item.mediaImageID}
                      src={
                        window.location.origin +
                        `/admin/homepageImages/${item.homepageImagePath}`
                      }
                      style={{ width: "100%", marginTop: "-1px" }}
                      alt="text"
                    ></img>
                  </SwiperSlide>
                ) : null
              )}
        </Swiper>
      </Container>
      <div className="clearfix"></div>

      <Container>
        <Featured></Featured>
        <Popular></Popular>
        {/* <Highlights></Highlights> */}
        <Footer></Footer>
      </Container>
    </div>
  );
}
export default Home;
