import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { LoginContext } from "../../context/categoryContext";
import { SubCatContext } from "../../context/subCategoryContext";

function ProductCategory(): ReactElement {
  const { categoryData } = useContext(LoginContext);
  const { subCategoryData } = useContext(SubCatContext);

  //find any subcategories in a main categories

  const findSubCategoryName = (subCategoryData: any, id: any) => {
    return subCategoryData.find((element: any) => {
      if (element.subID === parseInt(id)) {
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <div className="widget-category mb-30">
      <h5 className="section-title style-1 mb-30 wow fadeIn animated">
        Machines
      </h5>

      <div className="accordion accordion-flush" id="accordionFlushExample">
        {categoryData &&
          categoryData.map((item: any) => (
            <div key={item.categoryID} className="accordion-item">
              <h2
                className="accordion-header"
                id={"flush-heading" + item.categoryID}
              >
                {findSubCategoryName(subCategoryData, item.categoryID) ? (
                  <Link to={`/search?p=${item.categoryGuid.toUpperCase()}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#flush-collapse" + item.categoryID}
                      aria-expanded="false"
                      aria-controls={"flush-collapse" + item.categoryID}
                    >
                      {item.categoryName}

                      <span
                        style={{ textAlign: "right", float: "right" }}
                        className="float-right text-right"
                      ></span>
                    </button>
                  </Link>
                ) : null}
              </h2>
              <div
                id={"flush-collapse" + item.categoryID}
                className="accordion-collapse collapse"
                aria-labelledby={"flush-heading" + item.categoryID}
                data-bs-parent="#accordionFlushExample"
              >
                {subCategoryData &&
                  subCategoryData.map((item2: any) =>
                    item2.categoryGuid === item.categoryGuid ? (
                      <div className="accordion-button collapsed">
                        {item2.subName}
                      </div>
                    ) : null
                  )}
              </div>
            </div>
          ))}
      </div>

      {/* {categoryData &&
        categoryData.map((item: any) => (
          <div className="accordion" id={"accord" + item.categoryID}>
            <div className="accordion-item">
              <h2 className="accordion-header" id={"heading" + item.categoryID}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#test" + item.categoryName}
                  aria-expanded="true"
                  aria-controls={item.categoryName}
                >
                  {item.categoryName}
                </button>
              </h2>
              <div
                id={"test" + item.categoryName}
                className="accordion-collapse collapse show"
                aria-labelledby={"heading" + item.categoryID}
                data-bs-parent={"accord" + item.categoryID}
              >
                {subCategoryData &&
                  subCategoryData.map((item2: any) =>
                    item2.categoryGuid === item.categoryGuid ? (
                      <div className="accordion-body">
                        <ul>
                          <li>{item2.subName}</li>
                        </ul>
                      </div>
                    ) : null
                  )}
              </div>
            </div>
          </div>
        ))} */}
      {/* <ul className="categories">
        {categoryData &&
          categoryData.map((item: any) => (
            <li key={item.categoryID}>
              <a href="shop-grid-right.html">{item.categoryName}</a>

              {subCategoryData &&
                subCategoryData.map((item2: any) =>
                  item2.categoryGuid === item.categoryGuid ? (
                    <ul className="categories">
                      <li>{item2.subName}</li>
                    </ul>
                  ) : null
                )}
            </li>
          ))}
      </ul> */}
    </div>
  );
}
export default ProductCategory;
