import React, { ReactElement } from "react";

function News(): ReactElement {
  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" rel="nofollow">
              Home
            </a>
            <span></span> Blog
            <span></span> Technology
          </div>
        </div>
      </div>
      <section className="mt-50 mb-50">
        <div className="container custom">
          <div className="row">
            <div className="col-lg-12">
              <div className="single-header mb-50 text-center">
                <h1 className="font-xxl text-brand">Our Blog</h1>
                <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                  <span className="post-by">32 Sub Categories</span>
                  <span className="post-on has-dot">1020k Article</span>
                  <span className="time-reading has-dot">480 Authors</span>
                  <span className="hit-count  has-dot">29M Views</span>
                </div>
              </div>
              <div className="loop-grid pr-30">
                <div className="row">
                  <div className="col-lg-4">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-1.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Sport
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h3 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            9 Things I Love About Shaving My Head
                          </a>
                        </h3>
                        <p className="post-exerpt mb-30">
                          At the Emmys, broadcast scripted shows created by
                          people of color gained ground relative to those
                          pitched by White show creators, while broadcast
                          scripted shows.
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on">
                              {" "}
                              <i className="fi-rs-clock"></i> 25 April 2022
                            </span>
                            <span className="hit-count has-dot">
                              126k Views
                            </span>
                          </div>
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-2.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Sport
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h3 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The World Caters to Average People
                          </a>
                        </h3>
                        <p className="post-exerpt mb-30">
                          At the Emmys, broadcast scripted shows created by
                          people of color gained ground relative to those
                          pitched by White show creators, while broadcast
                          scripted shows.
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on">
                              {" "}
                              <i className="fi-rs-clock"></i> 25 April 2022
                            </span>
                            <span className="hit-count has-dot">
                              126k Views
                            </span>
                          </div>
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-3.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Politic
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h3 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The litigants on the screen are not actors
                          </a>
                        </h3>
                        <p className="post-exerpt mb-30">
                          These people envy me for having a lifestyle they don’t
                          have, but the truth is, sometimes but the truth is,
                          sometimes I envy their lifestyle instead. Struggling
                          to sell one multi.
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on">
                              {" "}
                              <i className="fi-rs-clock"></i> 25 April 2022
                            </span>
                            <span className="hit-count has-dot">
                              126k Views
                            </span>
                          </div>
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-3">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-4.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Global
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Essential Qualities of Highly Successful Music
                          </a>
                        </h4>
                        <p className="post-exerpt mb-30 font-sm">
                          Graduating from a top accelerator or incubator can be
                          as career-defining for a startup founder ah
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-3">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-5.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Sport
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            9 Things I Love About Shaving My Head
                          </a>
                        </h4>
                        <p className="post-exerpt mb-30 font-sm">
                          Graduating from a top accelerator or incubator can be
                          as career-defining for a startup founder ah
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-3">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-6.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Technology
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Why Teamwork Really Makes The Dream Work
                          </a>
                        </h4>
                        <p className="post-exerpt mb-30 font-sm">
                          Graduating from a top accelerator or incubator can be
                          as career-defining for a startup founder ah
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-3">
                    <article className="wow fadeIn animated hover-up mb-30">
                      <div className="post-thumb img-hover-scale">
                        <a href="blog-post-right.html">
                          <img
                            src={
                              window.location.origin + `/imgs/blog/blog-1.jpg`
                            }
                            style={{ width: "100%" }}
                            alt="text"
                          ></img>
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            Watch
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The World Caters to Average People
                          </a>
                        </h4>
                        <p className="post-exerpt mb-30 font-sm">
                          Graduating from a top accelerator or incubator can be
                          as career-defining for a startup founder ah
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <a href="blog-post-right.html" className="text-brand">
                            Read more <i className="fi-rs-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default News;
