import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../../services/api.service";
import Loader from "../../Loader";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

interface User {
  userID: number;
  userGuid: string;
  userFirstname: string;
  userSurname: string;
  userEmail: string;
  userStatus: number;
  userPass: string;
  Country: number;
  account: boolean;
  currency: number;
  partner: number;
  priceClassID: number;
  adminContact: number;
  userHouseNo: string;
  userStreet: string;
  userPostcode: string;
  userCounty: string;
  userCity: string;
  userSubscribe: boolean;
  userTel: string;
}

function CustomerEdit(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [user, setUser] = useState<User>({
    userID: 0,
    userGuid: "",
    userFirstname: "",
    userSurname: "",
    userEmail: "",
    userStatus: 0,
    userPass: "",
    Country: 0,
    account: false,
    currency: 0,
    partner: 0,
    priceClassID: 0,
    adminContact: 0,
    userHouseNo: "",
    userStreet: "",
    userPostcode: "",
    userCounty: "",
    userCity: "",
    userSubscribe: false,
    userTel: "",
  });
  const location = useLocation();
  const [isError, setIsError] = useState(0);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);

  const [loading, setLoading] = useState(false);

  const apiService = new ApiService();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }
  const [countries, setCountries] = useState([] as any);

  const fetchAllCountries = async () => {
    const response = await apiService.getCountries();
    setCountries(response);
  };
  const fetchCustomer = async (e: string) => {
    setLoading(true);
    const response = await apiService.getCustomerByID(e);
    setUser(response);
    setLoading(false);

    const response2 = await apiService.getDeliveryAddressesByUserID(
      response.userGuid
    );

    setDeliveryAddresses(response2);
  };

  const onSubmit = (data: any) => {
    data.userStatus = data.userStatus === true ? 1 : 2;
    data.userID = user.userID;
    data.userGuid = user.userGuid;
    data.userPass = user.userPass;
    data.partner = data.partner === true ? 1 : 0;

    PostUser(data);
  };

  const PostUser = async (data: any) => {
    const response = await apiService.PostData("api/user/SaveUser", data);
    if (response === 1) {
      setIsError(1);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    } else {
      setIsError(2);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomer(String(id));
    fetchAllCountries();
    reset();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Customer Edit : {id}</h1>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-md-7">
                  <div className="table-responsive">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-sm-12">
                        <label htmlFor="userFirstname" className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userFirstname"
                          placeholder="First Name"
                          defaultValue={user.userFirstname}
                          {...register("userFirstname", {
                            required: "userFirstname is required",
                          })}
                        ></input>
                        {errors.userFirstname ? (
                          <>
                            {errors.userFirstname.type === "required" && (
                              <span className="small error">
                                {errors.userFirstname.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userSurname" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userSurname"
                          placeholder="Last Name"
                          defaultValue={user.userSurname}
                          {...register("userSurname", {
                            required: "userSurname is required",
                          })}
                        ></input>
                        {errors.userSurname ? (
                          <>
                            {errors.userSurname.type === "required" && (
                              <span className="small error">
                                {errors.userSurname.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userEmail" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userEmail"
                          placeholder="Email"
                          defaultValue={user.userEmail}
                          {...register("userEmail", {
                            required: "userEmail is required",
                          })}
                        ></input>
                        {errors.userEmail ? (
                          <>
                            {errors.userEmail.type === "required" && (
                              <span className="small error">
                                {errors.userEmail.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userTel" className="form-label">
                          Telephone
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userTel"
                          placeholder="Telephone"
                          defaultValue={user.userTel}
                          {...register("userTel", {
                            required: "userTel is required",
                          })}
                        ></input>
                        {errors.userTel ? (
                          <>
                            {errors.userTel.type === "required" && (
                              <span className="small error">
                                {errors.userTel.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userHouseNo" className="form-label">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userHouseNo"
                          placeholder="Company Name"
                          defaultValue={user.userHouseNo}
                          {...register("userHouseNo", {
                            required: "userHouseNo is required",
                          })}
                        ></input>
                        {errors.userHouseNo ? (
                          <>
                            {errors.userHouseNo.type === "required" && (
                              <span className="small error">
                                {errors.userHouseNo.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userStreet" className="form-label">
                          Address Line 1
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userStreet"
                          placeholder="Address Line 1"
                          defaultValue={user.userStreet}
                          {...register("userStreet", {
                            required: "userStreet is required",
                          })}
                        ></input>
                        {errors.userStreet ? (
                          <>
                            {errors.userStreet.type === "required" && (
                              <span className="small error">
                                {errors.userStreet.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userCity" className="form-label">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userCity"
                          placeholder="Address Line 2"
                          defaultValue={user.userCity}
                          {...register("userCity", {
                            required: "userCity is required",
                          })}
                        ></input>
                        {errors.userCity ? (
                          <>
                            {errors.userCity.type === "required" && (
                              <span className="small error">
                                {errors.userCity.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userCounty" className="form-label">
                          Town / City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userCounty"
                          placeholder="Town / City"
                          defaultValue={user.userCounty}
                          {...register("userCounty", {
                            required: "userCounty is required",
                          })}
                        ></input>
                        {errors.userCounty ? (
                          <>
                            {errors.userCounty.type === "required" && (
                              <span className="small error">
                                {errors.userCounty.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userPostcode" className="form-label">
                          Postcode / Zip
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="userPostcode"
                          placeholder="Postcode / Zip"
                          defaultValue={user.userPostcode}
                          {...register("userPostcode", {
                            required: "userPostcode is required",
                          })}
                        ></input>
                        {errors.userPostcode ? (
                          <>
                            {errors.userPostcode.type === "required" && (
                              <span className="small error">
                                {errors.userPostcode.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="country" className="form-label">
                          Price Class
                        </label>
                        <select
                          className="form-select"
                          id="country"
                          {...register("priceClassID", { required: true })}
                        >
                          <option value={0} selected={0 === user.priceClassID}>
                            Not Set
                          </option>
                          <option value={2} selected={2 === user.priceClassID}>
                            Domestic
                          </option>
                          <option value={1} selected={1 === user.priceClassID}>
                            Europe{" "}
                          </option>
                          <option value={3} selected={3 === user.priceClassID}>
                            International{" "}
                          </option>
                        </select>
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="country" className="form-label">
                          Country
                        </label>
                        <select
                          className="form-control select-active"
                          {...register("Country", { required: true })}
                        >
                          <option disabled value="">
                            Select country...
                          </option>
                          {countries &&
                            countries.map((item: any) => (
                              <option
                                selected={247 === item.countryID}
                                value={item.countryID}
                              >
                                {item.name}{" "}
                              </option>
                            ))}
                        </select>{" "}
                        {errors.Country ? (
                          <>
                            <span className="small error">
                              {errors.Country.message}
                            </span>
                          </>
                        ) : null}
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="currency" className="form-label">
                          Currency
                        </label>
                        <select
                          className="form-select"
                          id="country"
                          {...register("currency", { required: true })}
                        >
                          <option value={1} selected={1 === user.currency}>
                            USD
                          </option>
                          <option value={2} selected={2 === user.currency}>
                            GBP{" "}
                          </option>
                          <option value={3} selected={3 === user.currency}>
                            EUR
                          </option>
                        </select>
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="adminContact" className="form-label">
                          Admin Contact
                        </label>
                        <select
                          className="form-select"
                          id="adminContact"
                          {...register("adminContact", { required: true })}
                        >
                          <option value={2} selected={2 === user.adminContact}>
                            USA
                          </option>
                          <option value={1} selected={1 === user.adminContact}>
                            EUROPE
                          </option>
                        </select>
                      </div>

                      <div className="col-md-12">
                        <label
                          htmlFor="flexCheckDefault2"
                          className="form-label"
                        ></label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="userStatus"
                            defaultChecked={user.userStatus === 1}
                            {...register("userStatus", {})}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault2"
                          >
                            Status
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label
                          htmlFor="flexCheckDefault2"
                          className="form-label"
                        ></label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="partner"
                            defaultChecked={user.partner === 1}
                            {...register("partner", {})}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault2"
                          >
                            Partner
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label
                          htmlFor="flexCheckDefault2"
                          className="form-label"
                        ></label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="userSubscribe"
                            defaultChecked={user.userSubscribe}
                            {...register("userSubscribe", {})}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault2"
                          >
                            Subscribe
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label
                          htmlFor="flexCheckDefault2"
                          className="form-label"
                        ></label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="account"
                            defaultChecked={user.account}
                            {...register("account", {})}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault2"
                          >
                            Account Customer
                          </label>
                        </div>
                      </div>
                      <hr></hr>

                      <button
                        type="submit"
                        className="btn btn-fill-out btn-block hover-up"
                        name="login"
                      >
                        Save Customer Details
                      </button>
                      <span
                        className={`badge badge-pill badge ${
                          isError === 2 ? "red" : isError === 1 ? "green" : ""
                        } `}
                      >
                        {isError === 2 ? "Error" : isError === 1 ? "Saved" : ""}
                      </span>
                      <br></br>
                      <br></br>
                      <br></br>
                    </form>
                  </div>
                </div>

                <div className="col-md-4">
                  <h4>Delivery Addresses</h4>
                  <hr></hr>
                  <ul style={{ listStyleType: "square" }}>
                    {deliveryAddresses &&
                      deliveryAddresses.map((item2: any) => (
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          <p>
                            {item2.contactName +
                              "\n" +
                              item2.deliveryBuildingNo +
                              "\n" +
                              item2.deliveryStreet +
                              "\n" +
                              item2.deliveryPostalcode +
                              "\n" +
                              item2.deliveryRegion +
                              "\n" +
                              item2.country.name +
                              "\n"}
                          </p>

                          <hr></hr>
                        </div>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
export default CustomerEdit;
