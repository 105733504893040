import React, { useState, ReactElement, useEffect } from "react";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Product from "./components/Product";
import Login from "./components/Login";
import MyAccount from "./components/My-Account";
import Basket from "./components/Basket";
import News from "./components/News";
import TopHeader from "./components/shared/topHeader";
import Navigation from "./components/shared/navigation";
import Dashboard from "./components/Dashboard";
import AdminDashboard from "./components/Administration/Dashboard";
import Search from "./components/Search";
import { LoginProvider } from "./context/categoryContext";
import { ExchangeProvider } from "./context/exchangeContext";
import { SubCatProvider } from "./context/subCategoryContext";
import { AuthProvider } from "./context/authContext";
import { CartProvider } from "./context/cartContext";
import { ProductProvider } from "./context/prodContext";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Manuals from "./components/Manuals";
import Checkout from "./components/Checkout";
import Home from "./components/Home";
import Header from "./components/Header";
import "./index.css";
import Orders from "./components/Administration/Orders";

import PendingOrders from "./components/Administration/PendingOrders";
import Products from "./components/Administration/Products";
import ProductEdit from "./components/Administration/ProductEdit";
import Customers from "./components/Administration/Customers";
import OrderView from "./components/Administration/OrderView";

import MediaImages from "./components/Administration/MediaImages";
import HomePageImage from "./components/Administration/HomePageImage";

import Stats from "./components/Administration/Stats";
import TesaTape from "./components/TesaTape";
import CreateAccount from "./components/CreateAccount";
import ContactUs from "./components/Contact-Us";
import NotFound from "./components/NotFound";
import OrderHistory from "./components/OrderHistory";
import SaleComplete from "./components/SaleComplete";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Cookie from "js-cookie";
import CustomerOrder from "./components/CustomerOrder";
import ForgotPassword from "./components/ForgotPassword";
import PasswordSent from "./components/PasswordSent";

import AccountCreated from "./components/AccountCreated";
import AdminLogin from "./components/AdministrationLogin";
import { AdminProvider } from "./context/adminContext";
import ErrorBoundary from "./components/ErrorBoundary";
import PromoCodes from "./components/Administration/PromoCodes";
import PromoCodeEdit from "./components/Administration/PromoCodeEdit";
import ExchangeRates from "./components/Administration/ExchangeRates";
import EditAccount from "./components/EditAccount";
import CustomerEdit from "./components/Administration/Customers/CustomerEdit";
import ProductVariant15 from "./components/Administration/ProductVariant15";
import ProductVariant20 from "./components/Administration/ProductVariant20";

import Categories from "./components/Administration/Categories";
import SubCategories from "./components/Administration/SubCategories";
import Activate from "./components/Activate";
import PDFOrder from "./components/PDFOrder";
import { CountryProvider } from "./context/countriesContext";
import DeliveryAddresses from "./components/DeliveryAddresses";
import DeliveryAddressEdit from "./components/DeliveryAddressEdit";
import { SelectedCatProvider } from "./context/selectedCatContext";
import { SearchTextProvider } from "./context/searchContext";
import AccountEdited from "./components/AccountEdited";
import TagManager from "react-gtm-module";

import ReactGA from "react-ga";
import AddCategory from "./components/Administration/Categories/AddCategory";
import AddSubCategory from "./components/Administration/SubCategories/AddSubCategory";
// const TRACKING_ID = "G-9RJKSQNTVE"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

// const tagManagerArgs = {
//   gtmId: "GTM-55LT4RK",
// };

// TagManager.initialize(tagManagerArgs);

// Sentry.init({
//   dsn: "https://5426f1ba23c94d80953373e9ac6d4b34@o4505274150879232.ingest.sentry.io/4505274315177984",

//   integrations: [new Sentry.Replay()],

//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function App(): ReactElement {
  const [user, setUser] = useState({} as any);

  // Sentry.setUser({ email: user.userGuid });

  const checkLogIn = () => {
    //   const user = getUser();
    //   console.log("user " + JSON.stringify(user));
    //   setUserData(user);
    // const cookieRetrieve = Cookie.get("admintoken");
    // if (cookieRetrieve) {
    //   setUser(cookieRetrieve);
    // }
    //   //   setLoggedIn(true);
    //   //   console.log("cookie yes status " + loggedIn);
    //   //   return true;
    //   // } else {
    //   //   Cookie.set("token", "");
    //   //   Cookie.set("cart", "");
    //   //   Cookie.remove("token");
    //   //   Cookie.remove("cart");
    //   //   console.log("cookie no status " + loggedIn);
    //   //   return false;
    //   // }
    // };
  };

  const handleLogin = () => setUser({ id: "1", name: "robin" });
  const handleLogout = () => {
    setUser(null);
    <Navigate to="http://localhost:58106/" replace />;
  };

  useEffect(() => {
    checkLogIn();
  }, []);

  return (
    <div>
      <ReactNotifications />

      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <AuthProvider>
            <SearchTextProvider>
              <AdminProvider>
                <LoginProvider>
                  <SubCatProvider>
                    <CountryProvider>
                      <CartProvider>
                        <ProductProvider>
                          <ExchangeProvider>
                            <SelectedCatProvider>
                              <BrowserRouter>
                                <TopHeader />
                                <Navigation />
                                <Header />
                                {/* {user.name ? (
                        <button onClick={handleLogout}>Sign Out</button>
                      ) : (
                        <button onClick={handleLogin}>Sign In</button>
                      )} */}
                                {user.name ? <MainLayout /> : <AdminLayout />}
                              </BrowserRouter>
                            </SelectedCatProvider>
                          </ExchangeProvider>
                        </ProductProvider>
                      </CartProvider>
                    </CountryProvider>
                  </SubCatProvider>
                </LoginProvider>
              </AdminProvider>
            </SearchTextProvider>
          </AuthProvider>
        </ErrorBoundary>
      </I18nextProvider>
    </div>
  );
}

function MainLayout(): ReactElement {
  const cookieAdminRetrieve = Cookie.get("adminToken");
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  const cookieRetrieve = Cookie.get("token");

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (cookieRetrieve !== "") {
      setIsLoggedIn(true);
    }
    if (cookieAdminRetrieve !== "") {
      setIsAdminLoggedIn(true);
    }
  }, [isLoggedIn, isAdminLoggedIn, cookieRetrieve, cookieAdminRetrieve]);

  return (
    <div>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="product" element={<Product />} />
        {/* <Route path="search" element={<Search />} /> */}
        <Route path="checkout" element={<Checkout />} />
        <Route path="login" element={<Login />} />
        <Route path="My-Account" element={<MyAccount />} />
        <Route path="Basket" element={<Basket />} />
        <Route path="News" element={<News />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="manuals" element={<Manuals />} />
        <Route path="TesaTape" element={<TesaTape />} />
        <Route path="Create-Account" element={<CreateAccount />} />

        <Route
          path="Edit-Account"
          element={isLoggedIn ? <EditAccount /> : <Navigate to="/login" />}
        />

        <Route
          path="Delivery-Addresses"
          element={
            isLoggedIn ? <DeliveryAddresses /> : <Navigate to="/login" />
          }
        />

        <Route path="Delivery-Address-Edit" element={<DeliveryAddressEdit />} />
        <Route path="Contact-Us" element={<ContactUs />} />
        <Route path="Order-History" element={<OrderHistory />} />
        <Route path="Sale-Complete" element={<SaleComplete />} />
        <Route path="Customer-Order" element={<CustomerOrder />} />
        <Route path="Forgot-Password" element={<ForgotPassword />} />
        <Route path="Password-Sent" element={<PasswordSent />} />
        <Route path="Account-Created" element={<AccountCreated />} />
        <Route path="Account-Edited" element={<AccountEdited />} />

        {/* <Route path="Administration" element={<AdminLogin />} />
        <Route path="Administration/Dashboard" element={<AdminDashboard />} />
        <Route path="Administration/Orders" element={<Orders />} />
        <Route path="Administration/Products" element={<Products />} />
        <Route path="Administration/ProductEdit" element={<ProductEdit />} />
        <Route path="Administration/Customers" element={<Customers />} />
        <Route path="Administration/OrderView" element={<OrderView />} />
        <Route path="Administration/Stats" element={<Stats />} />
        <Route path="Administration/Media-Images" element={<MediaImages />} />
        <Route path="Administration/Promo-Codes" element={<PromoCodes />} />
        <Route path="Administration/VariantEdit" element={<VariantEdit />} />
        <Route path="Administration/CustomerEdit" element={<CustomerEdit />} />
        <Route path="Administration/Categories" element={<Categories />} />
        <Route
          path="Administration/SubCategories"
          element={<SubCategories />}
        />

        <Route
          path="Administration/Exchange-Rates"
          element={<ExchangeRates />}
        />
        <Route
          path="Administration/Promo-Code-Edit"
          element={<PromoCodeEdit />}
        />

        <Route
          path="Administration/HomePage-Images"
          element={<HomePageImage />}
        /> */}
        <Route path="Activate" element={<Activate />} />

        <Route path="PDFOrder" element={<PDFOrder />} />
      </Routes>
    </div>
  );
}

function AdminLayout(): ReactElement {
  const cookieAdminRetrieve = Cookie.get("adminToken");
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  const cookieRetrieve = Cookie.get("token");

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (cookieRetrieve !== "") {
      setIsLoggedIn(true);
    }
    if (cookieAdminRetrieve !== "") {
      setIsAdminLoggedIn(true);
    }
  }, [isLoggedIn, isAdminLoggedIn, cookieRetrieve, cookieAdminRetrieve]);

  return (
    <div>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path="Dashboard"
          element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="product" element={<Product />} />
        <Route path="search" element={<Search />} />
        <Route path="checkout" element={<Checkout />} />

        <Route
          path="My-Account"
          element={isLoggedIn ? <MyAccount /> : <Navigate to="/login" />}
        />

        <Route
          path="Delivery-Addresses"
          element={
            isLoggedIn ? <DeliveryAddresses /> : <Navigate to="/login" />
          }
        />

        <Route path="Basket" element={<Basket />} />
        <Route path="News" element={<News />} />

        <Route
          path="manuals"
          element={isLoggedIn ? <Manuals /> : <Navigate to="/login" />}
        />

        <Route path="TesaTape" element={<TesaTape />} />
        <Route path="Create-Account" element={<CreateAccount />} />
        <Route
          path="Edit-Account"
          element={isLoggedIn ? <EditAccount /> : <Navigate to="/login" />}
        />
        <Route path="Contact-Us" element={<ContactUs />} />

        <Route
          path="Order-History"
          element={isLoggedIn ? <OrderHistory /> : <Navigate to="/login" />}
        />

        <Route
          path="Sale-Complete"
          element={isLoggedIn ? <SaleComplete /> : <Navigate to="/login" />}
        />

        <Route path="Password-Sent" element={<PasswordSent />} />

        <Route
          path="Customer-Order"
          element={isLoggedIn ? <CustomerOrder /> : <Navigate to="/login" />}
        />

        <Route path="Forgot-Password" element={<ForgotPassword />} />
        <Route path="Account-Created" element={<AccountCreated />} />
        <Route path="Account-Edited" element={<AccountEdited />} />

        <Route
          path="Administration"
          element={
            isAdminLoggedIn ? <AdminLogin /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/Categories/Add-Category"
          element={
            isAdminLoggedIn ? (
              <AddCategory />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/SubCategories/Add-SubCategory"
          element={
            isAdminLoggedIn ? (
              <AddSubCategory />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Dashboard"
          element={
            isAdminLoggedIn ? (
              <AdminDashboard />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Orders"
          element={
            isAdminLoggedIn ? <Orders /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/Pending-Orders"
          element={
            isAdminLoggedIn ? (
              <PendingOrders />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Products"
          element={
            isAdminLoggedIn ? <Products /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/ProductEdit"
          element={
            isAdminLoggedIn ? (
              <ProductEdit />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Customers"
          element={
            isAdminLoggedIn ? <Customers /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/OrderView"
          element={
            isAdminLoggedIn ? <OrderView /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/Stats"
          element={
            isAdminLoggedIn ? <Stats /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/Media-Images"
          element={
            isAdminLoggedIn ? (
              <MediaImages />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/ProductVariant15"
          element={
            isAdminLoggedIn ? (
              <ProductVariant15 />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/ProductVariant20"
          element={
            isAdminLoggedIn ? (
              <ProductVariant20 />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/CustomerEdit"
          element={
            isAdminLoggedIn ? (
              <CustomerEdit />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/HomePage-Images"
          element={
            isAdminLoggedIn ? (
              <HomePageImage />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Promo-Codes"
          element={
            isAdminLoggedIn ? <PromoCodes /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/Promo-Code-Edit"
          element={
            isAdminLoggedIn ? (
              <PromoCodeEdit />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Exchange-Rates"
          element={
            isAdminLoggedIn ? (
              <ExchangeRates />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Administration/Categories"
          element={
            isAdminLoggedIn ? <Categories /> : <Navigate to="/administration" />
          }
        />

        <Route
          path="Administration/SubCategories"
          element={
            isAdminLoggedIn ? (
              <SubCategories />
            ) : (
              <Navigate to="/administration" />
            )
          }
        />

        <Route
          path="Delivery-Address-Edit"
          element={
            isLoggedIn ? <DeliveryAddressEdit /> : <Navigate to="/login" />
          }
        />

        <Route
          path="PDFOrder"
          element={isLoggedIn ? <PDFOrder /> : <Navigate to="/login" />}
        />

        <Route path="Activate" element={<Activate />} />
      </Routes>
    </div>
  );
}

export default App;
