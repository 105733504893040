import React, { useState, createContext, useEffect, useContext } from "react";
import { ApiService } from "../services/api.service";
import { getAdminUser, clearAdminUser } from "../services/auth.service";

interface AdminProviderProps {
  children: React.ReactNode;
}

export const AdminContext = createContext({} as any);

export const AdminProvider = ({ children }: AdminProviderProps) => {
  // const [userData, setUserData] = useState({
  //   // userGuid: "",
  //   // userFirstName: "",
  //   // refToken: "",
  //   // currency: "",
  //   // userSurname: "",
  //   // userEmail: "",
  //   // userStatus: 0,
  //   // Country: 0,
  //   // account: 0,
  //   // partner: 0,
  //   // priceClassID: 0,
  //   // adminContact: 0,
  //   // userHouseNo: "",
  //   // userStreet: "",
  //   // userPostcode: "",
  //   // userCounty: "",
  //   // userCity: "",
  //   // userSubscribe: "",
  //   displayName: "",
  // });

  const [adminUserData, setAdminUserData] = useState({} as any | null);

  const apiService = new ApiService();

  useEffect(() => {
    const authUser = async () => {
      const user = getAdminUser();
      if (user === undefined) {
        clearAdminUser();
      } else {
        setAdminUserData(user);
      }
    };
    authUser();
  }, []);

  return (
    <AdminContext.Provider value={{ adminUserData, setAdminUserData }}>
      {children}
    </AdminContext.Provider>
  );
};
