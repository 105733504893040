import React, { useState, createContext, useEffect } from "react";
import { ApiService } from "../services/api.service";
interface SubCatProviderProps {
  children: React.ReactNode;
}

export const SubCatContext = createContext({
  subCategoryData: [],
  setSubCategories: (loggedIn: []) => {},
});

export const SubCatProvider = ({ children }: SubCatProviderProps) => {
  const [subCategoryData, setSubCategories] = useState([]);

  const apiService = new ApiService();
  useEffect(() => {
    const fetchSubCategories = async () => {
      const response = await apiService.getSubCategories();
      setSubCategories(response);
    };
    fetchSubCategories();
  }, []);

  return (
    <SubCatContext.Provider value={{ subCategoryData, setSubCategories }}>
      {children}
    </SubCatContext.Provider>
  );
};
