import React, { ReactElement } from "react";
function NotFound(): ReactElement {
  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>404 Not Found</h3>
      <hr></hr>;
    </div>
  );
}
export default NotFound;
