import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

function PasswordSent(): ReactElement {
  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Forgot Password
          </div>
        </div>
      </div>
      <section className="pt-50 pb-50">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="pb-30">Password details sent </h3>
                      </div>

                      <p>
                        A password link has been sent to your email if you have
                        an account with us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PasswordSent;
