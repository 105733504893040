import React, { ReactElement, useState, useEffect } from "react";
import { ApiService } from "../../../services/api.service";
import Loader from "../../Loader";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

function ExchangeRates(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const [exchangeRates, setExchangeRates] = useState<any | null>(null);

  const [exchangeArray, setExchangeArray] = useState([
    {
      exchangeID: 0,
      exchangeRate: 0,
    },
  ]);

  const [isError, setIsError] = useState(0);

  const handleInputChange = (index: any, event: any, exchangeRate: any) => {
    const values: any = [...exchangeRates];

    const updatedValue = event.target.name;

    values[index][updatedValue] = parseFloat(event.target.value);
    values[index]["exchangeID"] = exchangeRate;
    setExchangeRates(values);
  };

  const [loading, setLoading] = useState(false);

  const apiService = new ApiService();

  const fetchExchange = async () => {
    const response = await apiService.getExchangeRates();
    setExchangeRates(response);
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    PostData(exchangeRates);
  };

  const PostData = async (data: any) => {
    const response = await apiService.PostData(
      "api/Exchange/SaveExchangeRates",
      data
    );

    if (response === 1) {
      setIsError(1);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    } else {
      setIsError(2);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExchange();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Exchange Rates</h1>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Rate Name</th>
                        <th scope="col">Rate</th>
                        <th scope="col">db</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exchangeRates &&
                        exchangeRates.map((item: any, index: number) => (
                          <tr>
                            <td>{item.exchangeID}</td>
                            <td>Rate Name</td>
                            <td>
                              <input
                                type="text"
                                defaultValue={item.exchangeRate}
                                name="exchangeRate"
                                onChange={(event: any) =>
                                  handleInputChange(
                                    index,
                                    event,
                                    item.exchangeID
                                  )
                                }
                              />
                            </td>
                            <td>{item.exchangeRate}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <button
                    type="submit"
                    className="btn btn-fill-out btn-block hover-up"
                    name="login"
                  >
                    Save Exchange Rates
                  </button>
                  <span
                    className={`badge badge-pill badge ${
                      isError === 2 ? "red" : isError === 1 ? "green" : ""
                    } `}
                  >
                    {isError === 2 ? "Error" : isError === 1 ? "Saved" : ""}
                  </span>
                </form>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
export default ExchangeRates;
