import React, { useState, createContext } from "react";

interface LoginProviderProps {
  children: React.ReactNode;
}

const contextInitialState: {
  selectedCat: string;
  setSelectedCat: React.Dispatch<React.SetStateAction<string>>;
} = {
  selectedCat: "",
  setSelectedCat: () => "",
};

export const SelectedCatContext = createContext([] as any);

export const SelectedCatProvider = ({ children }: LoginProviderProps) => {
  const [selectedCat, setSelectedCat] = useState("");

  const value = { selectedCat, setSelectedCat };

  return (
    <SelectedCatContext.Provider value={value}>
      {children}
    </SelectedCatContext.Provider>
  );
};
