import React, { ReactElement, useState, useContext } from "react";
import Cookie from "js-cookie";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import { CartContext } from "../../context/cartContext";
import { generateUUID } from "../../helpers";
import { setUser, getUser } from "../../services/auth.service";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

function ForgotPassword(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const [userEmail, setUserEmail] = useState("");
  let [hidden, setHidden] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setHidden("Please enter a valid email address");
      return false;
    } else {
      setHidden("");
      return true;
    }
  };

  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    if (validateEmail(data.userEmail) === true) {
      fetch("api/email/Post", {
        method: "post",
        headers: {
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          emailAddress: data.userEmail,
        }),
      })
        .then((Response) => Response.json())
        .then((result) => {
          navigate("/Password-Sent");
        })
        .catch((error) => {
          setHidden("There was a problem with those details.");
        });
    }
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Forgot Password
          </div>
        </div>
      </div>
      <section className="pt-50 pb-50">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="pb-30">Forgot Password</h3>
                        {hidden !== "" ? (
                          <h4 className="pb-30 error">{hidden}</h4>
                        ) : null}
                      </div>
                      <form name="loginForm" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Your Email"
                            {...register("userEmail", {
                              required: "Email address is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Email is invalid",
                              },
                            })}
                          />
                          {errors.userEmail ? (
                            <>
                              {errors.userEmail.type === "required" && (
                                <span className="small error">
                                  {errors.userEmail.message}
                                </span>
                              )}
                              {errors.userEmail.type === "pattern" && (
                                <span className="small error">
                                  {errors.userEmail.message}
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-fill-out btn-block hover-up"
                            name="login"
                          >
                            Send password reset{" "}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="mb-30">
                          Create a Mark Andy Parts Account
                        </h3>
                      </div>
                      <p className="mb-20 font-sm">
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account, and for other purposes described in our
                        privacy policy
                      </p>
                      <form method="post">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                          ></input>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                          ></input>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            name="password"
                            placeholder="Confirm password"
                          ></input>
                        </div>
                        <div className="login_footer form-group">
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id="exampleCheckbox12"
                                value=""
                              ></input>
                              <label className="form-check-label">
                                <span>I agree to terms &amp; conditions.</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-fill-out btn-block hover-up"
                            name="login"
                          >
                            Submit &amp; Register
                          </button>
                        </div>
                      </form>
                      <div className="divider-text-center mt-15 mb-15">
                        <span> or</span>
                      </div>
                      {/* <ul className="btn-login list_none text-center mb-15">
                        <li>
                          <a
                            href="#"
                            className="btn btn-facebook hover-up mb-lg-0 mb-sm-4"
                          >
                            Login With Facebook
                          </a>
                        </li>
                        <li>
                          <a href="#" className="btn btn-google hover-up">
                            Login With Google
                          </a>
                        </li>
                      </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ForgotPassword;
