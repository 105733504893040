import React, { ReactElement, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Cookie from "js-cookie";
import { AdminContext } from "../../../context/adminContext";

function AdminHeader(): ReactElement {
  const navigate = useNavigate();

  const { adminUserData, setAdminUserData } = useContext(AdminContext);

  const checkLogIn = () => {
    const CookieVal = Cookie.get("Admintoken");
    if (CookieVal === undefined) {
      navigate("../Administration");
    }
  };

  useEffect(() => {
    checkLogIn();
  }, []);

  const LogOut = () => {
    Cookie.remove("Admintoken");
    navigate("../Administration");
  };

  return (
    <div>
      <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-1 shadow">
        <Link
          className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6"
          to="/Administration"
        >
          <img
            className="mt-10"
            src={window.location.origin + `/imgs/ma_logo_invert.png`}
            style={{ width: "100%" }}
            alt="text"
          ></img>
        </Link>
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <input
          className="form-control form-control-dark w-100 rounded-0 border-0"
          type="text"
          placeholder="Search"
          aria-label="Search"
        ></input> */}
        <div className="navbar-nav">
          <div className="nav-item text-nowrap">
            <a className="nav-link px-3" onClick={(e) => LogOut()}>
              Sign out
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}
export default AdminHeader;
