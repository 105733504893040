import React, { ReactElement, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { LoginContext } from "../../context/categoryContext";
import {
  currencySelector,
  FormatDate,
  FormatBasePrice,
  FormatGeneralPrice,
  FormatMoney,
} from "../../helpers";
import { ApiService } from "../../services/api.service";
import { AuthContext } from "../../context/authContext";
import Loader from "../Loader";
import ButtonLoaderSmall from "../ButtonLoaderSmall";

interface Order {
  saleID: number;
  userGuid: string;
  saleGBPTotal: number;
  saleEuroTotal: number;
  saleDate: string;
  saleComplete: number;
  saleDespatch: number;
  cartGuid: string;
  deliveryID: number;
  currencyID: number;
  poNumber: string;
  discountApplied: number;
  deliveryAddress: string;
  ProductsBought: string;
  discountcouponused: string;
}

interface User {
  userGuid: string;
  userFirstname: string;
  userHouseNo: string;
  userSurname: string;
}

function CustomerOrder(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const { userData, setUserData } = useContext(AuthContext);

  const [saleItems, setSaleItems] = useState([]);
  const [userGuid, setUserGuid] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [loading, setLoading] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadResponse, setUploadResponse] = useState("");

  const [validOrder, setValidOrder] = useState(false);

  const [order, setOrder] = useState<Order>({
    saleID: 0,
    userGuid: "",
    saleGBPTotal: 0,
    saleEuroTotal: 0,
    saleDate: "",
    saleComplete: 0,
    saleDespatch: 0,
    cartGuid: "",
    deliveryID: 0,
    currencyID: 0,
    poNumber: "",
    discountApplied: 0,
    deliveryAddress: "",
    ProductsBought: "",
    discountcouponused: "",
  });

  const [user, setUser] = useState<User>({
    userGuid: "",
    userFirstname: "",
    userHouseNo: "",
    userSurname: "",
  });

  const FinaliseTotal = (total: any, deliveryCharge: any, discount: any) => {
    if (total && deliveryCharge) {
      var newTotal = FormatBasePrice(total);
      var discCalc = FormatBasePrice(discount);

      var calcTotal = newTotal + deliveryCharge + discCalc;

      return FormatGeneralPrice(calcTotal);
    }
  };

  const onFileChange = (e: any) => {
    // Update the state
    setSelectedFile(e.target.files[0]);
  };

  const apiService = new ApiService();

  const fetchOrder = async (e: number) => {
    setLoading(true);
    const response = await apiService.getOrderByID(e);
    if (response.userGuid === userData.userGuid) setValidOrder(true);
    setOrder(response);
    setUserGuid(response.userGuid);
    fetchUser(String(response.userGuid));
    setLoading(false);
  };

  const onFileUpload = (e: any) => {
    e.preventDefault();
    setUploading(true);
    // Create an object of formData
    const formData: any = new FormData();
    // Update the formData object
    formData.append("file", selectedFile, order.saleID.toString());

    PostData(formData);
  };

  const PostData = async (data: any) => {
    setUploading(true);
    const response = await apiService.PostFile("api/User/UploadPO", data);

    if (response.ok) {
      setUploadResponse("Your Purchase Order has been uploaded");
      setUploadComplete(true);
    } else {
      setUploadResponse("Your Purchase Order has not been uploaded");
    }
    setUploading(false);
  };

  const fetchSaleItems = async (e: number) => {
    const response = await apiService.getSaleItemsByID(e);
    setSaleItems(response);
    setDeliveryCharge(response[0].deliveryCharge);
  };

  const fetchUser = async (e: string) => {
    const response = await apiService.getUserByID(e);
    setUser(response);
  };

  useEffect(() => {
    if (userData) {
      fetchOrder(Number(id));
      fetchSaleItems(Number(id));
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 mt-50">
            <div className="container">
              <div className="page-header" style={{ textAlign: "left" }}></div>

              {loading ? (
                <Loader></Loader>
              ) : validOrder ? (
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-12">
                          <div className="text-center">
                            <h2>MA00{order.saleID}</h2>
                            <p>{FormatDate(new Date(order.saleDate))}</p>
                            <p></p>
                          </div>

                          <hr></hr>

                          <div className="row">
                            <table
                              className="table table-bordered"
                              style={{ textAlign: "center" }}
                            >
                              <tr style={{ fontWeight: "bold" }}>
                                <td style={{ width: "33%" }}>
                                  Customer Details
                                </td>

                                <td style={{ width: "33%" }}>
                                  Delivery Address
                                </td>

                                <td style={{ width: "33%" }}>Preferences</td>
                              </tr>

                              <tr>
                                <td style={{ width: "33%" }}>
                                  {" "}
                                  {user.userFirstname} {user.userSurname}
                                  <br></br>
                                  {user.userHouseNo}
                                </td>

                                <td style={{ width: "33%" }}>
                                  {order.deliveryAddress}{" "}
                                </td>

                                <td style={{ width: "33%" }}>
                                  {order.discountApplied ? (
                                    <div>
                                      <strong>Coupon: </strong>
                                      {currencySelector(
                                        order.currencyID,
                                        order.discountApplied
                                      )}
                                      ({order.discountcouponused})
                                      <br />
                                    </div>
                                  ) : null}
                                  <strong>PO: </strong>
                                  {order.poNumber}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h3 className="text-center">
                                <strong>Order Summary</strong>
                              </h3>

                              <p
                                className="text-center"
                                style={{ padding: "20px" }}
                              >
                                {deliveryCharge === 0
                                  ? "*Delivery charges may be calculated and confirmed prior to dispatching of items."
                                  : null}
                              </p>
                            </div>
                            <div className="panel-body">
                              <div className="table-responsive">
                                <table className="table table-condensed">
                                  <thead>
                                    <tr>
                                      <td style={{ width: "60%" }}>
                                        <strong>Item Name</strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>Price</strong>
                                      </td>
                                      <td className="text-center">
                                        <strong>Quantity</strong>
                                      </td>
                                      <td className="text-right">
                                        <strong>Total</strong>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {saleItems &&
                                      saleItems.map((item: any) => (
                                        <tr>
                                          <td>
                                            <strong>
                                              <h2>
                                                {item.product.productName}
                                              </h2>
                                            </strong>
                                            <p>
                                              {item.serial} {item.machine}
                                              {item.subProductVariantDetails}
                                            </p>
                                            <p>
                                              {" "}
                                              {item.product.productDetails}
                                            </p>
                                          </td>

                                          <td className="text-center">
                                            {currencySelector(
                                              order.currencyID,
                                              item.salePrice
                                            )}{" "}
                                          </td>

                                          <td className="text-center">
                                            {item.productQuantity}
                                          </td>
                                          <td className="text-right">
                                            {currencySelector(
                                              order.currencyID,
                                              item.salePrice *
                                                item.productQuantity
                                            )}
                                          </td>
                                        </tr>
                                      ))}

                                    {order.discountApplied ? (
                                      <tr>
                                        <td className="highrow"></td>
                                        <td className="highrow"></td>
                                        <td className="highrow text-center">
                                          <strong>Discount</strong>
                                        </td>
                                        <td className="highrow text-right">
                                          {order.discountApplied
                                            ? currencySelector(
                                                order.currencyID,
                                                order.discountApplied
                                              )
                                            : currencySelector(
                                                order.currencyID,
                                                0
                                              )}
                                        </td>
                                      </tr>
                                    ) : null}

                                    <tr>
                                      <td className="highrow"></td>
                                      <td className="highrow"></td>
                                      <td className="highrow text-center">
                                        <strong>Sub Total</strong>
                                      </td>
                                      <td className="highrow text-right">
                                        {currencySelector(
                                          order.currencyID,
                                          order.saleGBPTotal
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="highrow"></td>
                                      <td className="highrow"></td>
                                      <td className="highrow text-center">
                                        <strong>
                                          {deliveryCharge !== 0
                                            ? "Delivery"
                                            : "Delivery To Be Advised"}
                                        </strong>
                                      </td>
                                      <td className="highrow text-right">
                                        {currencySelector(
                                          order.currencyID,
                                          deliveryCharge
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="emptyrow"></td>
                                      <td className="emptyrow"></td>
                                      <td className="emptyrow text-center">
                                        <strong>Total</strong>
                                      </td>
                                      <td className="emptyrow text-right">
                                        {currencySelector(
                                          order.currencyID,
                                          deliveryCharge + order.saleGBPTotal
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-md-12"
                          style={{ marginBottom: "15px", textAlign: "center" }}
                        >
                          <div style={{ fontSize: "17px" }}>
                            <h4>Contact Us</h4>
                            <hr />
                          </div>

                          <div className="row">
                            <table
                              className="table"
                              style={{ textAlign: "center" }}
                            >
                              <tr>
                                <td style={{ verticalAlign: "top" }}>
                                  <h4 style={{ display: "inline" }}>
                                    Mark Andy Europe
                                  </h4>
                                  <br />
                                  Queens Avenue
                                  <br />
                                  Hurdsfield Industrial Estate
                                  <br />
                                  Macclesfield, <br />
                                  Cheshire SK10 2BN
                                  <br />
                                  England
                                  <br />
                                  Phone +44 1 625 500 964
                                  <br />
                                  Fax +44 1 625 501 334
                                  <br />
                                  <a href="mailto:euspares@markandy.com">
                                    euspares@markandy.com
                                  </a>{" "}
                                </td>

                                <td>
                                  <h4 style={{ display: "inline" }}>
                                    Mark Andy Inc.
                                  </h4>
                                  <br />
                                  18081 Chesterfield <br />
                                  Airport Road
                                  <br />
                                  Chesterfield
                                  <br />
                                  USA
                                  <br />
                                  MO 63005
                                  <br />
                                  Tel - 001 636 532 4433
                                  <br />
                                  USA Toll Free 800 700 6275
                                  <br />
                                  <a href="mailto:parts@yourfriendinflexo.com">
                                    parts@yourfriendinflexo.com
                                  </a>
                                </td>
                              </tr>
                            </table>

                            <div className="col-6 col-md-6 col-lg-6"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="container p-5 mb-4 bg-light rounded-3">
                        {fileExists ? (
                          <p>Purchase Order Uploaded</p>
                        ) : (
                          <p>Upload a PDF purchase order</p>
                        )}
                        {uploadComplete ? (
                          <a
                            href={`./assets/PO/MA${order.saleID.toString()}/Purchase_Order.pdf`}
                          >
                            Your Purchase Order
                          </a>
                        ) : (
                          <span>
                            <input
                              className="inputfile"
                              type="file"
                              onChange={onFileChange}
                            />
                            <button
                              className="btn btn-primary"
                              onClick={onFileUpload}
                            >
                              {uploading ? (
                                <ButtonLoaderSmall></ButtonLoaderSmall>
                              ) : (
                                <span>Upload a Purchase Order</span>
                              )}
                            </button>
                          </span>
                        )}
                        <hr></hr>

                        {fileExists ? (
                          <a
                            href={`./assets/PO/MA${order.saleID.toString()}/Purchase_Order.pdf`}
                            onError={() => setFileExists(false)}
                            target="_blank"
                          >
                            View Purchase Order
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <h2>Not a valid order</h2>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default CustomerOrder;
