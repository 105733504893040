import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import {
  currencySelector,
  FormatDate,
  FormatMoney,
  FormatBasePrice,
  FormatGeneralPrice,
} from "./../../helpers";
import { ApiService } from "./../../services/api.service";
import Loader from "./../Loader";
function OrderHistory(): ReactElement {
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(AuthContext);
  const [saleItems, setSaleItems] = useState([]);
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  const [orders, setOrders] = useState([]);

  const apiService = new ApiService();

  const FinaliseTotal = (total: any, deliveryCharge: any) => {
    if (total && deliveryCharge) {
      var newTotal = FormatBasePrice(total);

      var calcTotal = newTotal + deliveryCharge;

      return FormatGeneralPrice(calcTotal);
    }
  };

  const findSaleID = (saleItems: any, saleID: string) => {
    return saleItems.find((element: any) => {
      return element.categoryGuid === saleID.toLowerCase();
    });
  };

  const navigate = useNavigate();

  const fetchAllOrdersByUser = async () => {
    setLoading(true);
    const response = await apiService.getOrderByUserID(userData.userGuid);
    setOrders(response);
    setLoading(false);
    window.scrollTo(0, 0);
    fetchSaleItems(response.saleID);
  };

  const fetchSaleItems = async (e: number) => {
    const response = await apiService.getSaleItemsByID(e);
    setSaleItems(response);
  };

  useEffect(() => {
    fetchAllOrdersByUser();
  }, []);

  function handleClick(item: any) {
    navigate("/Customer-Order/?id=" + item);
  }

  return (
    <div>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/">Home</Link>
              <span></span> Pages
              <span></span> Order History
            </div>
          </div>
        </div>
        <section className="pt-10 pb-150">
          <div className="container">
            <div className="row">
              <h3 className="mb-20 mt-20">Order History</h3>
              <hr></hr>

              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    {loading ? (
                      <Loader></Loader>
                    ) : (
                      <table className="table table-striped table-sm">
                        {" "}
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" style={{ width: "45%" }}>
                              Delivery Address
                            </th>
                            <th scope="col">Date</th>
                            {/* <th scope="col">Sub Total (excl delivery)</th> */}
                            {/* <th scope="col">Discount</th> */}
                            <th scope="col">PO No.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders &&
                            orders.map((item: any) => (
                              <tr
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(item.saleID)}
                              >
                                <td>{"MA00" + item.saleID}</td>

                                <td>{item.deliveryAddress}</td>
                                <td>{FormatDate(item.saleDate)}</td>
                                {/* <td>{FormatMoney(item.saleGBPTotal)}</td>
                                <td>{FormatMoney(item.discountApplied)}</td> */}
                                <td>{item.poNumber}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
export default OrderHistory;
