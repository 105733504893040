import Cookie from "js-cookie";
import Base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import AES from "crypto-js/aes";
import SHA256 from "crypto-js/sha256";
import { getBearer } from "../helpers";
const cookieRetrieve = Cookie.get("token");

export class ApiService {
  public async getOrders(): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/Sale/GetAllSalesByComplete", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getPendingOrders(): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/Sale/GetAllSalesByPending", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getRelatedProducts(id: number): Promise<any> {
    const response = await fetch("api/Related/GetAllProductsRelated/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await response.json();
  }

  public async getAllMediaImages(): Promise<any> {
    const response = await fetch("api/MediaImage/GetAllMediaImages");
    return await response.json();
  }

  public async getAllHomePageImages(): Promise<any> {
    const response = await fetch("api/HomePageImage/GetAllHomepageImages");
    return await response.json();
  }

  public async getAllPromoCodes(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Promo/GetAllPromoCodes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getPromoCodeAdmin(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Promo/GetPromoCodeAdminByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getSaleItemsByID(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/SaleItem/GetAllSaleItemsBySaleID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getDeliveryAddressesByUserID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      "api/DeliveryAddress/GetDeliveryAddressByUserID/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getDeliveryAddressByID(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      "api/DeliveryAddress/GetDeliveryAddressByID/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getOrderByUserID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Sale/GetSaleByUserID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getOrderByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch("api/Sale/GetSaleByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getProducts(id: number): Promise<any> {
    const response = await fetch("api/Product/GetAllProductsTest");
    return await response.json();
  }

  public async getPromoCode(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/Promo/GetPromoCodeByName/" + id, {
      method: "get",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getProductCategoryByProductID(id: number): Promise<any> {
    const response = await fetch(
      "api/ProductCategory/GetProductCategoriesByProductID/" + id
    );
    return await response.json();
  }

  public async getProductSubCategoryByProductID(id: number): Promise<any> {
    const response = await fetch(
      "api/ProductSubCategory/GetProductSubCategoriesByProductID/" + id
    );
    return await response.json();
  }

  public async getProductCategoryByCategoryID(id: number): Promise<any> {
    const response = await fetch(
      "api/ProductCategory/FindProductCategoriesByCategoryID/" + id
    );
    return await response.json();
  }

  public async getProductsByKeyword(id: string): Promise<any> {
    const response = await fetch("api/Product/GetAllProductsAdmin/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${cookieRetrieve}`,
      },
    });
    return await response.json();
  }

  public async getOrdersByKeyword(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/Sale/GetAllOrdersByKeywordAdmin/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getCustomerByID(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/user/GetCustomerByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getCustomerByKeyword(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/user/GetAllUsers/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getCustomerAll(): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/user/GetEveryUser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getUserByID(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/user/GetUserByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getProductByID(id: number): Promise<any> {
    const response = await fetch("api/Product/GetProductByID/" + id);
    return await response.json();
  }

  public async getAllProducts(): Promise<any> {
    const response = await fetch("api/Product/GetAllProducts");
    return await response.json();
  }

  public async getAllProductsLive(): Promise<any> {
    const response = await fetch("api/Product/GetAllProductsLive");
    return await response.json();
  }

  public async getProductVariantsID(id: number): Promise<any> {
    const response = await fetch(
      "api/ProductVariant/GetProductVariantByID/" + id
    );
    return await response.json();
  }

  public async getSubProductVariantBySuperID(id: number): Promise<any> {
    const response = await fetch(
      "api/SubProductVariant/GetAllBySubProductVariantID/" + id
    );
    return await response.json();
  }

  public async getCart(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/Cart/GetAllCartsByID/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async checkFile(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/user/CheckIfFileExists/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getExchangeRates(): Promise<any> {
    const response = await fetch("api/Exchange/GetAllExchangeRates", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${cookieRetrieve}`,
      },
    });
    return await response.json();
  }
  public async getCountries(): Promise<any> {
    const response = await fetch("api/Country/GetAllCountries");
    return await response.json();
  }

  public async authUser(userEmail: string, userPassword: string): Promise<any> {
    const response = await fetch("api/user/CreateToken", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        Username: userEmail,
        Password: userPassword,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async createCartItem(
    productID: number,
    productQuantity: number,
    price: number,
    guid: string,
    productVariantID: number,
    subProductVariantID: number
  ): Promise<any> {
    const response = await fetch("api/cart/CreateCartItem", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        productID: productID,
        productQuantity: productQuantity,
        price: price,
        guid: guid,
        productVariantID: productVariantID,
        subProductVariantID: subProductVariantID,
      }),
    });

    return await response.json();
  }

  public async getProductsByCategoryArray(id: string): Promise<any> {
    const response = await fetch("api/Product/GetProductByCategoryArray/" + id);
    return await response.json();
  }

  public async getProductsByCategory(id: string): Promise<any> {
    const response = await fetch("api/Product/GetProductByCategory/" + id);
    return await response.json();
  }

  public async getProductCategoryByCategory(id: number, pageNumber: number): Promise<any> {
    const response = await fetch(
      "api/ProductCategory/FindProductsByCategoryID/"  +
      id +
      "/" +
      pageNumber);
    return await response.json();
  }

  public async getProductsByCategoryPagination(
    categoryID: number,
    pageSize: number,
    pageNumber: number
  ): Promise<any> {
    const response = await fetch(
      "api/Product/GetProductByCategoryPageination/" +
        categoryID +
        "/" +
        pageSize +
        "/" +
        pageNumber
    );
    return await response.json();
  }

  public async deleteItemFromCart(id: number, guider: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      "api/Cart/DeleteItemFromCart/" + id + "/" + guider,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async deleteHomepageImage(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch("api/HomepageImage/DeleteHomeImage/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getProductsBySubGuid(id: string): Promise<any> {
    const response = await fetch(
      "api/Product/GetProductBySubCategoryGuid/" + id
    );
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async getProductsBySubID(id: string): Promise<any> {
    const response = await fetch(
      "api/ProductSubCategory/GetProductsBySubCategory/" + id
    );
    return await response.json();
  }

  public async getProductsBySearch(search: any): Promise<any> {
    const response = await fetch("api/Product/GetProductBySearch/" + search);
    return await response.json();
  }

  public async getCategories(): Promise<any> {
    const response = await fetch("api/Category/GetAllCategories");
    return await response.json();
  }

  public async getCountryByCountryID(id: number): Promise<any> {
    const response = await fetch("api/Country/GetCountryByCountryID/" + id);
    return await response.json();
  }

  public async getSubCategories(): Promise<any> {
    const response = await fetch("api/SubCategory/GetAllSubCategories");
    return await response.json();
  }

  public async getSubCategoriesByCategoryID(id: string): Promise<any> {
    const response = await fetch(
      "api/SubCategory/GetAllSubCategoriesByID/" + id
    );
    return await response.json();
  }

  public async addUser(user: any) {
    const response = await fetch(`/api/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user }),
    });
    return await response.json();
  }

  public async addUserAccount(user: any) {
    const response = await fetch(`/api/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user }),
    });
    return await response.json();
  }

  public async PostData(url: any, data: any) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostDataWithAuth(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostDeliveryAddress(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostImageFileData(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: data,
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostFile(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      body: data,
    });
    return response;
  }
}
