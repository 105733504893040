import React, { ReactElement, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { ApiService } from "../../../services/api.service";
import Loader from "../../Loader";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { FormatDate } from "../../../helpers";
import DatePicker from "react-date-picker";
import { useLocation } from "react-router";

function PromoCodes(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const apiService = new ApiService();

  const [promoCodes, setPromoCodes] = useState<any | null>(null);

  const fetchPromo = async () => {
    const response = await apiService.getAllPromoCodes();
    setPromoCodes(response);
  };

  function handleClick(item: any) {
    navigate("/Administration/Promo-Code-Edit?id=" + item);
  }

  useEffect(() => {
    fetchPromo();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Promo Codes</h1>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Code</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Value</th>
                      <th scope="col">Continent</th>
                      <th scope="col">Start</th>
                      <th scope="col">End</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promoCodes &&
                      promoCodes.map((item: any) => (
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(item.promoID)}
                        >
                          <td>{item.promoID}</td>
                          <td>{item.promoCode}</td>
                          <td>{item.promoName}</td>
                          <td>{item.promoStatus}</td>
                          <td>{item.promoValue}</td>
                          <td>{item.promoCont}</td>
                          <td>{FormatDate(item.dateStart)}</td>
                          <td>{FormatDate(item.dateEnd)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
export default PromoCodes;
