import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { ApiService } from "../../../services/api.service";
import Loader from "../../Loader";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { SearchContext } from "../../../context/searchContext";

function Products(): ReactElement {
  const {
    searchText,
    setSearchText,
    searchData,
    setSearchData,
    searchType,
    setSearchType,
  } = useContext(SearchContext);

  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  function handleClick(item: any) {
    navigate("/Administration/ProductEdit?id=" + item);
  }

  const handleKeyword = (e: any) => {
    if (e.length >= 3) {
      setLoading(true);
      setSearchText(e);
      setSearchKeyword(e);
      const apiService = new ApiService();
      const fetchProductsByKeyword = async () => {
        const response = await apiService.getProductsByKeyword(e);
        setProducts(response);
        setLoading(false);
        setSearchData(response);
        setSearchType("Products");
      };

      fetchProductsByKeyword();
    }
  };

  useEffect(() => {
    if (searchData && searchType === "Products") {
      setProducts(searchData);
    }
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>

      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Products</h1>
              {/* <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Export
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary dropdown-toggle"
                >
                  <span
                    data-feather="calendar"
                    className="align-text-bottom"
                  ></span>
                  This week
                </button>
              </div> */}
            </div>

            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Product Search</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter keyword"
                  style={{ textAlign: "left", marginBottom: "0px" }}
                  onChange={(e) => handleKeyword(e.target.value)}
                />
                <Form.Text className="text-muted">
                  Type to search for a product
                </Form.Text>
              </Form.Group>
            </Form>

            {loading ? (
              <Loader />
            ) : products.length != 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "10%" }}>
                        Catalogue Image
                      </th>
                      <th scope="col">Oracle ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Master Price ($)</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.map((item: any) => (
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(item.productID)}
                        >
                          <td style={{ width: "10%" }}>
                            {" "}
                            <img
                              src={
                                window.location.origin +
                                `/admin/catalogue/${item?.productName}.jpg`
                              }
                              style={{
                                maxWidth: "60px",
                              }}
                              alt={"NO IMAGE"}
                            ></img>
                          </td>
                          <td>{item.productID}</td>
                          <td>{item.productName}</td>
                          <td>{item.productPriceDollar}</td>
                          <td>{item.productStatus}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </main>
        </div>
      </div>
    </div>
  );
}
export default Products;
