import React, { ReactElement, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { LoginContext } from "../../../context/categoryContext";
import { ApiService } from "../../../services/api.service";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import { FormatDate } from "../../../helpers";

interface PromoCode {
  promoID: number;
  promoCode: string;
  promoName: string;
  promoStatus: number;
  promoValue: number;
  promoCont: string;
  dateStart: string;
  dateEnd: string;
}

function PromoCodeEdit(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [value, onChange] = useState(new Date());

  const [promoCode, setPromoCode] = useState<PromoCode>({
    promoID: 0,
    promoCode: "",
    promoName: "",
    promoStatus: 0,
    promoValue: 0,
    promoCont: "",
    dateStart: "",
    dateEnd: "",
  });

  const apiService = new ApiService();

  const fetchPromoCode = async (e: number) => {
    const response = await apiService.getPromoCodeAdmin(e);
    setPromoCode(response);
    var datey = new Date(response.dateStart);
    onChange(datey);
  };

  const onSubmit = (data: any) => {
    // PostData(data);
  };

  const PostData = async (data: any) => {
    const response = await apiService.PostData("api/User/AddUser", data);
  };

  useEffect(() => {
    fetchPromoCode(Number(id));
    reset();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                <b>{promoCode.promoName}</b>
              </h1>
            </div>

            <div className="row g-3">
              <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-sm-12">
                    <label htmlFor="firstName" className="form-label">
                      Promo Code Name
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="productName"
                      placeholder="Oracle ID"
                      defaultValue={promoCode.promoName}
                      {...register("productName", {
                        required: "productName is required",
                      })}
                    ></input>
                    {errors.promoName ? (
                      <>
                        {errors.promoName.type === "required" && (
                          <span className="small error">
                            {errors.promoName.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="lastName" className="form-label">
                      Promo Code Status
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Product Intro"
                      defaultValue={promoCode.promoStatus}
                      {...register("productIntro", {})}
                    ></input>
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="lastName" className="form-label">
                      Promo Code Continent{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Text on product"
                      defaultValue={promoCode.promoCont}
                      {...register("flash", {})}
                    ></input>
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="lastName" className="form-label">
                      Promo Code Value{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Product Details"
                      defaultValue={promoCode.promoValue}
                      {...register("productDetails", {})}
                    ></input>
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="lastName" className="form-label">
                      Promo Code Date Start{" "}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Stock Level"
                      defaultValue={FormatDate(new Date(promoCode.dateStart))}
                      {...register("stockLevel", {
                        required: "stockLevel is required",
                      })}
                    ></input>
                    {errors.dateStart ? (
                      <>
                        {errors.dateStart.type === "required" && (
                          <span className="small error">
                            {errors.dateStart.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <label htmlFor="username" className="form-label">
                      Promo Code Date End{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Stock Level"
                      defaultValue={FormatDate(new Date(promoCode.dateEnd))}
                      {...register("stockLevel", {
                        required: "stockLevel is required",
                      })}
                    ></input>
                    {errors.dateEnd ? (
                      <>
                        {errors.dateEnd.type === "required" && (
                          <span className="small error">
                            {errors.dateEnd.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <hr></hr>
                  <button className="btn btn-primary btn-user btn-block">
                    Save Promo Code{" "}
                  </button>
                </form>
              </div>
            </div>
            <hr></hr>

            <hr></hr>
          </main>
        </div>
      </div>
    </div>
  );
}
export default PromoCodeEdit;
