import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
function Loader(): ReactElement {
  return (
    <Container fluid>
      <div
        style={{
          color: "#333",
          width: "100%",
          textAlign: "center",
          paddingBottom: "150px",
        }}
      >
        <img src="./imgs/loader2.gif" style={{ width: "200px" }} alt=""></img>
      </div>
    </Container>
  );
}

export default Loader;
