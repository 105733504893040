import { paletteColours } from "./palette";

export interface HEXColours {
  [key: string]: string;
}

const getColours = () => {
  const hexColours = {};

  (Object.keys(paletteColours) as Array<keyof typeof paletteColours>).forEach(
    (colour) =>
      Object.assign(hexColours, { [colour]: paletteColours[colour].HEX })
  );

  return hexColours;
};

export const colours: HEXColours = getColours();
