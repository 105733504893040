import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { colours } from "../../styles/colours";

const S = {
  Header: styled.header`
    // background-color: ${colours.MARKANDY_RED};
    color: ${colours.WHITE};
  `,
};

function Header(): ReactElement {
  return (
    <div>
      <S.Header></S.Header>
    </div>
  );
}

export default Header;
