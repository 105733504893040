import React, { ReactElement, useState, useEffect } from "react";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useNavigate } from "react-router";
import { ApiService } from "../../../services/api.service";
import { useLocation } from "react-router";
import { FormatCash, FormatEUR, FormatGBP, FormatUSD } from "../../../helpers";

import Moment from "moment";

function Stats(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const [orders, setOrders] = useState([] as any[]);
  const [products, setProducts] = useState<any | null>(null);
  const [liveProducts, setLiveProducts] = useState<any | null>(null);

  const apiService = new ApiService();

  const [productCount, setProductCount] = useState(0);
  const navigate = useNavigate();

  const fetchAllOrders = async () => {
    const response = await apiService.getOrders();
    setOrders(response);
    window.scrollTo(0, 0);
  };

  const fetchAllProducts = async () => {
    const response = await apiService.getAllProducts();
    setProductCount(response);
    window.scrollTo(0, 0);
  };

  const calculateItemsUSD = (year: string, curr: number) => {
    return orders
      .filter(
        ({ saleDate, currencyID }) =>
          Moment(saleDate).format("YYYY") === year && currencyID === curr
      )
      .reduce((acc, current) => acc + Number(current.saleGBPTotal), 0);
  };

  const fetchAllProductsLive = async () => {
    const response = await apiService.getAllProductsLive();
    setLiveProducts(response);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchAllProducts();
    fetchAllProductsLive();
    fetchAllOrders();
  }, []);
  return (
    <div>
      <AdminHeader></AdminHeader>

      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Stats and Financials</h1>
              <div className="clearfix"></div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center border-bottom pb-2 mb-3">
                <p>Products Live</p>
                <h2>{liveProducts}</h2>
              </div>
              <div className="col-md-6 text-center border-bottom pb-2 mb-3">
                <p>Products In Database</p>
                <h2>{productCount}</h2>
              </div>
            </div>

            <table className="table table-bordered table-striped">
              <tr>
                <td>
                  <strong>Year</strong>
                </td>
                <td>
                  <strong>USD ($)</strong>
                </td>
                <td>
                  <strong>GBP (£)</strong>
                </td>
                <td>
                  <strong>EURO (€)</strong>
                </td>
              </tr>
              <tr>
                <td>2021</td>
                <td>{FormatUSD(calculateItemsUSD("2021", 1))}</td>
                <td>{FormatGBP(calculateItemsUSD("2021", 2))}</td>
                <td>{FormatEUR(calculateItemsUSD("2021", 3))}</td>
              </tr>
              <tr>
                <td>2022</td>
                <td>{FormatUSD(calculateItemsUSD("2022", 1))}</td>
                <td>{FormatGBP(calculateItemsUSD("2022", 2))}</td>
                <td>{FormatEUR(calculateItemsUSD("2022", 3))}</td>
              </tr>
              <tr>
                <td>2023</td>
                <td>{FormatUSD(calculateItemsUSD("2023", 1))}</td>
                <td>{FormatGBP(calculateItemsUSD("2023", 2))}</td>
                <td>{FormatEUR(calculateItemsUSD("2023", 3))}</td>
              </tr>
            </table>
          </main>
        </div>
      </div>
    </div>
  );
}
export default Stats;
