import type { ReactElement } from "react";

export interface ColourType {
  [key: string]: string;
}

export interface Colours {
  [key: string]: ColourType;
}

export const paletteColours: Colours = {
  // Brand colours

  MARKANDY_RED: {
    HEX: "#8c2332",
  },
  MARKANDY_BLUE: {
    HEX: "#004a9f",
  },
  DARK_GREY: {
    HEX: "#222222",
  },
  LIGHT_GREY: {
    HEX: "#939598",
  },
  LIGHT_BG: {
    HEX: "#f5f5f5",
  },
  TEXT_GREY: {
    HEX: "#859999",
  },
  BLACK: {
    HSL: "hsl(0,0%,0%)",
    RGB: "rgb(0,0,0)",
    HEX: "#000000",
  },

  WHITE: {
    HSL: "hsl(0,0%,100%)",
    RGB: "rgb(255,255,255)",
    HEX: "#ffffff",
  },
};
