import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from "react";
import { useLocation } from "react-router";
import { LoginContext } from "../../../context/categoryContext";
import { ApiService } from "../../../services/api.service";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import { SubCatContext } from "../../../context/subCategoryContext";
import Loader from "../../Loader";
import { Link } from "react-router-dom";

interface Product {
  productName: string;
  productIntro: string;
  flash: string;
  stockLevel: number;
  productDetails: string;
  productPriceDollar: number;
  productPriceEuro: number;
  productStatus: number;
  award: number;
  productID: number;
  locallySourced: number;
  pallet: number;
  productSuperVariantID: number;
  productCategory: {
    categoryID: number;
  };
  useCloudImage: number;
}

interface ProductCategory {
  categoryID: number;
  productCategoryID: number;
  productID: number;
}

declare global {
  interface Window {
    cloudinary: any;
  }
}

function ProductEdit(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [loading, setLoading] = useState(false);

  const [productStatus, setProductStatus] = useState(0);
  const [savedStatus, setSavedStatus] = useState(false);
  const [savingStatus, setSavingStatus] = useState(false);

  const [product, setProduct] = useState<Product>({
    productName: "",
    productIntro: "",
    flash: "",
    stockLevel: 0,
    productDetails: "",
    productPriceDollar: 0,
    productPriceEuro: 0,
    productStatus: 0,
    award: 0,
    productID: 0,
    locallySourced: 0,
    pallet: 0,
    productCategory: {
      categoryID: 0,
    },
    useCloudImage: 0,
    productSuperVariantID: 0,
  });

  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);

  const { categoryData } = useContext(LoginContext);
  const { subCategoryData } = useContext(SubCatContext);

  const apiService = new ApiService();

  const fetchProduct = async (e: number) => {
    setLoading(true);
    const response = await apiService.getProductByID(e);
    setProduct(response);
    setProductStatus(response.productStatus);
    setLoading(false);
  };

  const fetchSubCategories = async (e: number) => {
    const response2 = await apiService.getProductSubCategoryByProductID(e);
    setProductSubCategories(response2);
  };

  const fetchProductCategories = async (e: number) => {
    const response = await apiService.getProductCategoryByProductID(e);
    setProductCategories(response);
  };

  const checkIfProductCatExists = (categoryData: any, categoryID: string) => {
    return categoryData.find((element: any) => {
      return element.categoryID === categoryID;
    });
  };

  const getProductCategoryID = (categoryData: any, categoryID: string) => {
    categoryData.find((element: any, obj: any) => {
      if (element.categoryID === categoryID) return obj;
    });
  };

  const checkIfSubProductCatExists = (categoryData: any, subID: string) => {
    return categoryData.find((element: any) => {
      return element.subID === subID;
    });
  };

  const onSubmit = (data: any) => {
    PostProductData(data);
  };

  function findByMatchingProperties(set: any, properties: any) {
    return set.filter(function (entry: any) {
      return Object.keys(properties).every(function (key) {
        return entry[key] === properties[key];
      });
    });
  }
  const Uploader = () => {
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: "cloud2mad",
        uploadPreset: "ml_default",
      },
      (error: any, result: any) => {
        if (!error && result && result.event === "success") {
          fetchProduct(product.productID);
        }
      }
    );
    widget.open();
  };

  const saveProductCategory = (categoryID: any) => {
    let data = {
      productCategoryID: 0,
      categoryID: 0,
      productID: 0,
    };
    var result = (productCategories as ProductCategory[]).filter((obj) => {
      return obj.categoryID === categoryID;
    });

    data.categoryID = categoryID;
    data.productID = product.productID;
    data.productCategoryID = result[0]?.productCategoryID
      ? result[0]?.productCategoryID
      : 0;

    PostData(data);
  };

  const PostData = async (data: any) => {
    setSavedStatus(false);
    const response = await apiService.PostData(
      "api/ProductCategory/DeleteProductCategory",
      data
    );
  };
  const PostProductData = async (data: any) => {
    setSavingStatus(true);
    data.productStatus = data.productStatus === true ? 1 : 2;
    data.locallySourced = data.locallySourced === true ? 1 : 2;
    data.pallet = data.pallet === true ? 1 : 2;
    data.useCloudImage = data.useCloudImage === true ? 1 : 2;

    const response = await apiService.PostData("api/Product/SaveProduct", data);
    setSavingStatus(false);
    if (response === 1) {
      setSavedStatus(true);
      setTimeout(closeTimeout, 3000);
    }
  };

  function closeTimeout() {
    setSavedStatus(false);
  }

  useEffect(() => {
    fetchProductCategories(Number(id));
    fetchSubCategories(Number(id));
    fetchProduct(Number(id));
    reset();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          {loading ? (
            <Loader></Loader>
          ) : (
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Edit: <b>{product.productName}</b>
                </h1>
                <Link
                  className="float:right"
                  style={{ fontSize: "16px" }}
                  to={`/Administration/VariantEdit?id=${id}`}
                >
                  Edit Variants
                </Link>
                {/* <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Export
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary dropdown-toggle"
                >
                  <span
                    data-feather="calendar"
                    className="align-text-bottom"
                  ></span>
                  This week
                </button>
              </div> */}
              </div>

              <div className="row g-3">
                <div className="col-7">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-sm-12">
                      <label htmlFor="lastName" className="form-label">
                        Oracle ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productID"
                        placeholder="Oracle ID"
                        defaultValue={product.productID}
                        {...register("productID", {
                          required: "productID is required",
                        })}
                      ></input>
                      {errors.productID ? (
                        <>
                          {errors.productID.type === "required" && (
                            <span className="small error">
                              {errors.productID.message}
                            </span>
                          )}
                        </>
                      ) : null}
                    </div>

                    <div className="col-sm-12">
                      <label htmlFor="firstName" className="form-label">
                        Product Name
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="productName"
                        placeholder="Product Name"
                        defaultValue={product.productName}
                        {...register("productName", {
                          required: "productName is required",
                        })}
                      ></input>
                      {errors.productName ? (
                        <>
                          {errors.productName.type === "required" && (
                            <span className="small error">
                              {errors.productName.message}
                            </span>
                          )}
                        </>
                      ) : null}
                    </div>

                    <div className="col-sm-12">
                      <label htmlFor="lastName" className="form-label">
                        Product Intro
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productIntro"
                        placeholder="Product Intro"
                        defaultValue={product.productIntro}
                        {...register("productIntro", {})}
                      ></input>
                    </div>

                    <div className="col-sm-12">
                      <label htmlFor="lastName" className="form-label">
                        Text Badge on Product{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="flash"
                        placeholder="Text on product"
                        defaultValue={product.flash}
                        {...register("flash", {})}
                      ></input>
                    </div>

                    <div className="col-sm-12">
                      <label htmlFor="lastName" className="form-label">
                        Product Details{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productDetails"
                        placeholder="Product Details"
                        defaultValue={product.productDetails}
                        {...register("productDetails", {})}
                      ></input>
                    </div>

                    <div className="col-sm-12">
                      <label htmlFor="lastName" className="form-label">
                        Stock Level{" "}
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="stockLevel"
                        placeholder="Stock Level"
                        defaultValue={product.stockLevel}
                        {...register("stockLevel", {
                          required: "stockLevel is required",
                        })}
                      ></input>
                      {errors.stockLevel ? (
                        <>
                          {errors.stockLevel.type === "required" && (
                            <span className="small error">
                              {errors.stockLevel.message}
                            </span>
                          )}
                        </>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">
                        Master Price{" "}
                      </label>
                      <div className="input-group has-validation">
                        <span className="input-group-text">$</span>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          id="productPriceDollar"
                          defaultValue={product.productPriceDollar}
                          {...register("productPriceDollar", {
                            required: "Price is required",
                          })}
                        ></input>
                        {errors.productPriceDollar ? (
                          <>
                            {errors.productPriceDollar.type === "required" && (
                              <span className="small error">
                                {errors.productPriceDollar.message}
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">
                        Price Override{" "}
                      </label>
                      <div className="input-group has-validation">
                        <span className="input-group-text">$</span>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          id="productPriceEuro"
                          defaultValue={product.productPriceEuro}
                          {...register("productPriceEuro")}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="country" className="form-label">
                        Visbility
                      </label>
                      <select
                        className="form-select"
                        id="country"
                        {...register("award", { required: true })}
                      >
                        <option value="0" selected={0 === product.award}>
                          Show All
                        </option>
                        <option value="1" selected={1 === product.award}>
                          Show Only Europe
                        </option>
                        <option value="2" selected={2 === product.award}>
                          Show Only USA
                        </option>
                        <option value="3" selected={3 === product.award}>
                          Show shop.markandy message to USA customers
                        </option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="country" className="form-label">
                        Product Super Variant
                      </label>
                      <select
                        className="form-select"
                        id="country"
                        {...register("ProductSuperVariantID", {
                          required: true,
                        })}
                      >
                        <option
                          value="0"
                          selected={0 === product.productSuperVariantID}
                        >
                          None
                        </option>
                        <option
                          value="1"
                          selected={1 === product.productSuperVariantID}
                        >
                          15 Thou
                        </option>
                        <option
                          value="2"
                          selected={2 === product.productSuperVariantID}
                        >
                          20 Thou
                        </option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="flexCheckDefault2"
                        className="form-label"
                      ></label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="productStatus"
                          defaultChecked={product.productStatus === 1}
                          {...register("productStatus", {})}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault2"
                        >
                          Status
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="country" className="form-label"></label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="locallySourced"
                          defaultChecked={product.locallySourced === 1}
                          {...register("locallySourced", {})}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Locally Sourced
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="useCloudImage"
                        className="form-label"
                      ></label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="useCloudImage"
                          defaultChecked={product.useCloudImage === 1}
                          {...register("useCloudImage", {})}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Use Cloud Image
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="country" className="form-label"></label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="pallet"
                          defaultChecked={1 === product.pallet}
                          {...register("pallet", {})}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Delivery Cost as Pallet
                        </label>
                      </div>
                    </div>
                    <hr></hr>
                    <button className="btn btn-primary btn-user btn-block">
                      {savingStatus ? "Saving..." : "Save Product"}
                    </button>
                  </form>

                  {savedStatus ? (
                    <div
                      className="alert alert-success"
                      style={{ marginTop: "25px" }}
                      role="alert"
                    >
                      Saved successfully
                    </div>
                  ) : null}
                </div>

                <div className="col-5">
                  <div className="row">
                    <div className="col-12">
                      <h4>Original Image</h4>
                      <hr></hr>
                      <img
                        className="img-thumbnail"
                        src={
                          window.location.origin +
                          `/admin/catalogue/${product?.productName}.jpg`
                        }
                        style={{
                          maxWidth: "100%",
                        }}
                        alt={product.productName}
                      ></img>
                      <h4>Cloudinary Uploader</h4>
                      <hr></hr>
                      <img
                        className="img-thumbnail"
                        src={
                          window.location.origin +
                          `/imgs/Cloudinary_logo.svg.png`
                        }
                        style={{
                          maxWidth: "50%",
                          textAlign: "center",
                        }}
                      ></img>
                      <img
                        className="img-thumbnail"
                        src={`http://res.cloudinary.com/cloud2mad/image/upload/v1677440009/${product?.productName}.jpg`}
                        style={{
                          maxWidth: "100%",
                        }}
                        alt=""
                      ></img>
                      <br></br>
                      <button
                        id="upload_widget"
                        onClick={(e) => Uploader()}
                        className="cloudinary-button"
                      >
                        Upload files
                      </button>
                      <hr></hr>
                      <ul>
                        {categoryData &&
                          categoryData.map((item: any) => (
                            <div
                              className="form-check"
                              style={{ marginLeft: "25px" }}
                            >
                              <ul style={{ paddingLeft: "25px" }}>
                                {subCategoryData &&
                                  subCategoryData.map((item2: any) =>
                                    item2.categoryGuid === item.categoryGuid ? (
                                      <li
                                        style={{
                                          marginLeft: "20px",
                                          color: "#888",
                                        }}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="flexCheckDefault"
                                          defaultChecked={checkIfSubProductCatExists(
                                            productSubCategories,
                                            item2.subID
                                          )}
                                        ></input>
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckDefault"
                                        >
                                          {item2.subName}
                                        </label>
                                      </li>
                                    ) : null
                                  )}
                              </ul>

                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                defaultChecked={checkIfProductCatExists(
                                  productCategories,
                                  item.categoryID
                                )}
                                onChange={(e) =>
                                  saveProductCategory(item.categoryID)
                                }
                              ></input>
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                {item.categoryName}
                              </label>
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>

              <hr></hr>
            </main>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProductEdit;
