import React, { ReactElement, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { colours } from "../../styles/colours";

import { CartContext } from "../../context/cartContext";
import { CalculateTotal, FormatMoney, FormatGeneralPrice } from "../../helpers";

function Cart(): ReactElement {
  const { cart, setCart } = useContext(CartContext);
  const { totalPrice, setTotalPrice } = useContext(CartContext);

  const total = totalPrice;

  const cartData = cart;

  const removeItem = (e: any, guider: any) => {
    const apiService = new ApiService();
    const removeItemFromCart = async () => {
      const response = await apiService.deleteItemFromCart(e, guider);
      setCart(response);
      setTotalPrice(CalculateTotal(response));
    };
    removeItemFromCart();
    // const getCart = async () => {
    //   const response = await apiService.getCart(
    //     "F20DC29A-2C08-481D-88C0-577A9E478C8A"
    //   );
    //   setCart(response);
    //   setTotalPrice(CalculateTotal(response));
    // };
    // getCart();
  };

  return (
    <div>
      <div className="cart-dropdown-wrap cart-dropdown-hm2">
        {cartData.length > 0 ? (
          <ul>
            {cartData &&
              cartData.map((item) => (
                <li key={item.cartID}>
                  <div className="shopping-cart-img">
                    <Link to="/basket">
                      <img
                        src={
                          window.location.origin +
                          `/Admin/Catalogue/${item.product?.productName}.jpg`
                        }
                        style={{
                          width: "100%",
                        }}
                        alt="text"
                      ></img>
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4 style={{ color: "#8c2332" }}>
                      {item.product?.productName}
                    </h4>
                    <h4>
                      <span>{item.productQuantity} × </span>
                      {FormatGeneralPrice(item.price!)}
                    </h4>
                  </div>
                  <div className="shopping-cart-delete">
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={(e) => removeItem(item.cartID, item.guid)}
                      className="fi-rs-cross-small"
                    ></i>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <h5>You have no items in your basket</h5>
        )}
        <div className="shopping-cart-footer">
          <div className="shopping-cart-total">
            <h4>
              Total <span>{FormatGeneralPrice(total)}</span>
            </h4>
          </div>
          <div className="shopping-cart-button">
            <Link to="/basket" className="outline">
              View cart
            </Link>
            <Link to="/Checkout">Checkout</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cart;
