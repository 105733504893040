import { useEffect, useState, useContext } from "react";

import { AuthContext } from "../context/authContext";
import Cookie from "js-cookie";
import { ExchangeContext } from "../context/exchangeContext";
import Moment from "moment";

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const CalculateTotal = (carty: any) => {
  let subtotal = 0;
  carty.map((cartProduct: any) => {
    if (cartProduct.productVariantID === 1) {
      subtotal += cartProduct.productQuantity! * cartProduct.price!;
    } else {
      subtotal += cartProduct.productQuantity! * cartProduct.price!;
    }
  });
  return subtotal;
};

export function useCheckMobileScreen() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
}

export function FormatDate(date: Date) {
  return Moment(date).format("DD-MM-YYYY");
}

export function FormatCash(number: number) {
  return number.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  });
}

export function FormatGBP(number: number) {
  return number.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  });
}
export function FormatUSD(number: number) {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
export function FormatEUR(number: number) {
  return number.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
}

export function CalculateDiscount(discount: number, total: number) {
  let newTotal = total * discount;

  let finalTotal = newTotal - total;

  return finalTotal;
}

export function Truncate(str: string) {
  return str.length > 30 ? str.substring(0, 30) + " ..." : str;
}

export function currencySelector(currency: number, saleTotal: number) {
  if (currency === 1 && saleTotal) {
    return saleTotal.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  } else if (currency === 2 && saleTotal) {
    return saleTotal.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  } else if (currency === 3 && saleTotal) {
    return saleTotal.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }
}

export function FormatGeneralPrice(number: number) {
  const { userData } = useContext(AuthContext);

  if (userData.currency === 1) {
    return number.toLocaleString("en-US", {
      //USD
      style: "currency",
      currency: "USD",
    });
  } else if (userData.currency === 2) {
    //GBP
    return number.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  } else if (userData.currency === 3) {
    //Euro
    return number.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }
}

export const FormatBasePrice = (number: any) => {
  const { userData } = useContext(AuthContext);
  const { exchangeData } = useContext(ExchangeContext);

  // 1 = USD
  if (userData.currency === 1) {
    let NEWDOLLAR;
    if (userData.priceClassID === 3) {
      NEWDOLLAR = number / 1.55;
      NEWDOLLAR = NEWDOLLAR + NEWDOLLAR * 0.25;

      return NEWDOLLAR;
    } else if (userData.priceClassID === 2) {
      NEWDOLLAR = number / 1.55;
      return NEWDOLLAR;
    } else {
      return number;
    }
    // 2 = GBP
  } else if (userData.currency === 2) {
    //GBP
    number = number * exchangeData[0].exchangeRate;
    return number;
    // 3 = EUR
  } else if (userData.currency === 3) {
    number = number * exchangeData[1].exchangeRate;

    //Euro
    return number;
  }
};

export const FormatBasePriceTest = (
  userData: any,
  exchangeData: any,
  number: any
) => {
  // 1 = USD
  if (userData.currency === 1) {
    let NEWDOLLAR;
    if (userData.priceClassID === 3) {
      NEWDOLLAR = number / 1.55;
      NEWDOLLAR = NEWDOLLAR + NEWDOLLAR * 0.25;

      return NEWDOLLAR;
    } else if (userData.priceClassID === 2) {
      NEWDOLLAR = number / 1.55;
      return NEWDOLLAR;
    } else {
      return number;
    }
    // 2 = GBP
  } else if (userData.currency === 2) {
    //GBP
    number = number * exchangeData[0].exchangeRate;
    return number;
    // 3 = EUR
  } else if (userData.currency === 3) {
    number = number * exchangeData[1].exchangeRate;

    //Euro
    return number;
  }
};

export function toFixed(num: any, fixed: any) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

export function getBearer() {
  let bearerToken;
  const cookieAdminRetrieve = Cookie.get("Admintoken");
  const cookieRetrieve = Cookie.get("token");
  if (cookieAdminRetrieve) {
    bearerToken = Cookie.get("Admintoken");
    return bearerToken;
  } else if (cookieRetrieve) {
    bearerToken = Cookie.get("token");
    return bearerToken;
  }
}

export function FormatMoney(number: any) {
  const { userData } = useContext(AuthContext);
  const { exchangeData } = useContext(ExchangeContext);

  number = toFixed(number, 2);

  //price class

  if (userData.currency === 1) {
    let NEWDOLLAR;
    if (userData.priceClassID === 3) {
      NEWDOLLAR = number / 1.55;
      NEWDOLLAR = NEWDOLLAR + NEWDOLLAR * 0.25;

      return NEWDOLLAR.toLocaleString("en-US", {
        //USD
        style: "currency",
        currency: "USD",
      });
    } else if (userData.priceClassID === 2) {
      NEWDOLLAR = number / 1.55;
      return NEWDOLLAR.toLocaleString("en-US", {
        //USD
        style: "currency",
        currency: "USD",
      });
    } else {
      return number.toLocaleString("en-US", {
        //USD
        style: "currency",
        currency: "USD",
      });
    }
  } else if (userData.currency === 2) {
    //GBP

    number = number * exchangeData[0]?.exchangeRate;

    return parseFloat(number).toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  } else if (userData.currency === 3) {
    number = number * exchangeData[1]?.exchangeRate;

    //Euro
    return number.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }
}
