import React, { ReactElement, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { LoginContext } from "../../context/categoryContext";
import {
  currencySelector,
  FormatDate,
  FormatBasePrice,
  FormatGeneralPrice,
  FormatMoney,
} from "../../helpers";
import { ApiService } from "../../services/api.service";
import { AuthContext } from "../../context/authContext";

interface Order {
  saleID: number;
  userGuid: string;
  saleGBPTotal: number;
  saleEuroTotal: number;
  saleDate: string;
  saleComplete: number;
  saleDespatch: number;
  cartGuid: string;
  deliveryID: number;
  currencyID: number;
  PONumber: string;
  discountApplied: number;
  deliveryAddress: string;
  ProductsBought: string;
  discountcouponused: string;
}

interface User {
  userGuid: string;
  userFirstname: string;
  userHouseNo: string;
  userSurname: string;
}

function PDFOrder(): ReactElement {
  var el = document.getElementById("MAheader");
  if (el !== null) {
    el.style.display = "none";
  }

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const { userData, setUserData } = useContext(AuthContext);

  const [saleItems, setSaleItems] = useState([]);
  const [userGuid, setUserGuid] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  const [validOrder, setValidOrder] = useState(false);

  const [order, setOrder] = useState<Order>({
    saleID: 0,
    userGuid: "",
    saleGBPTotal: 0,
    saleEuroTotal: 0,
    saleDate: "",
    saleComplete: 0,
    saleDespatch: 0,
    cartGuid: "",
    deliveryID: 0,
    currencyID: 0,
    PONumber: "",
    discountApplied: 0,
    deliveryAddress: "",
    ProductsBought: "",
    discountcouponused: "",
  });

  const [user, setUser] = useState<User>({
    userGuid: "",
    userFirstname: "",
    userHouseNo: "",
    userSurname: "",
  });

  const FinaliseTotal = (total: any, deliveryCharge: any, discount: any) => {
    if (total && deliveryCharge) {
      var newTotal = FormatBasePrice(total);
      var discCalc = FormatBasePrice(discount);

      var calcTotal = newTotal + deliveryCharge + discCalc;

      return FormatGeneralPrice(calcTotal);
    }
  };

  const apiService = new ApiService();

  const fetchOrder = async (e: number) => {
    const response = await apiService.getOrderByID(e);
    if (response.userGuid === userData.userGuid) setValidOrder(true);
    setOrder(response);
    setUserGuid(response.userGuid);
    fetchUser(String(response.userGuid));
  };

  const fetchSaleItems = async (e: number) => {
    const response = await apiService.getSaleItemsByID(e);
    setSaleItems(response);
    setDeliveryCharge(response[0].deliveryCharge);
  };

  const fetchUser = async (e: string) => {
    const response = await apiService.getUserByID(e);
    setUser(response);
  };

  useEffect(() => {
    if (userData) {
      fetchOrder(Number(id));
      fetchSaleItems(Number(id));
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 mt-50">
            <div className="container">
              <div className="page-header" style={{ textAlign: "left" }}></div>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">
                      <h2>MA00{order.saleID}</h2>
                      <p>{FormatDate(new Date(order.saleDate))}</p>
                      <p></p>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <table
                        className="table table-bordered"
                        style={{ textAlign: "center" }}
                      >
                        <tr style={{ fontWeight: "bold" }}>
                          <td style={{ width: "33%" }}>Customer Details</td>

                          <td style={{ width: "33%" }}>Delivery Address</td>

                          <td style={{ width: "33%" }}>Preferences</td>
                        </tr>

                        <tr>
                          <td style={{ width: "33%" }}>
                            {" "}
                            {user.userFirstname} {user.userSurname}
                            <br></br>
                            {user.userHouseNo}
                          </td>

                          <td style={{ width: "33%" }}>
                            {order.deliveryAddress}{" "}
                          </td>

                          <td style={{ width: "33%" }}>
                            <strong>Coupon: </strong>
                            {FormatMoney(order.discountApplied)} (
                            {order.discountcouponused})
                            <br />
                            <strong>PO: </strong>
                            {order.PONumber}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="text-center">
                          <strong>Order Summary</strong>
                        </h3>

                        <p className="text-center" style={{ padding: "20px" }}>
                          {deliveryCharge === 0
                            ? "*Delivery charges may be calculated and confirmed prior to dispatching of items."
                            : null}
                        </p>
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-condensed">
                            <thead>
                              <tr>
                                <td style={{ width: "60%" }}>
                                  <strong>Item Name</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Price</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Quantity</strong>
                                </td>
                                <td className="text-right">
                                  <strong>Total</strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {saleItems &&
                                saleItems.map((item: any) => (
                                  <tr>
                                    <td>
                                      <strong>
                                        <h2>{item.product.productName}</h2>
                                      </strong>
                                      {item.serial} {item.machine}
                                      {item.product.productDetails}
                                      <br />
                                    </td>

                                    <td className="text-center">
                                      {FormatMoney(item.salePrice)}
                                    </td>

                                    <td className="text-center">
                                      {item.productQuantity}
                                    </td>
                                    <td className="text-right">
                                      {FormatMoney(
                                        item.salePrice * item.productQuantity
                                      )}
                                    </td>
                                  </tr>
                                ))}

                              <tr>
                                <td className="highrow"></td>
                                <td className="highrow"></td>
                                <td className="highrow text-center">
                                  <strong>Discount</strong>
                                </td>
                                <td className="highrow text-right">
                                  {order.discountApplied
                                    ? FormatMoney(order.discountApplied)
                                    : 0}
                                </td>
                              </tr>

                              <tr>
                                <td className="highrow"></td>
                                <td className="highrow"></td>
                                <td className="highrow text-center">
                                  <strong>Sub Total</strong>
                                </td>
                                <td className="highrow text-right">
                                  {order.discountApplied
                                    ? FormatMoney(
                                        order.saleGBPTotal +
                                          order.discountApplied
                                      )
                                    : FormatMoney(order.saleGBPTotal)}
                                </td>
                              </tr>

                              <tr>
                                <td className="highrow"></td>
                                <td className="highrow"></td>
                                <td className="highrow text-center">
                                  <strong>
                                    {deliveryCharge !== 0
                                      ? "Delivery"
                                      : "Delivery To Be Advised"}
                                  </strong>
                                </td>
                                <td className="highrow text-right">
                                  {FormatGeneralPrice(deliveryCharge)}
                                </td>
                              </tr>

                              <tr>
                                <td className="emptyrow"></td>
                                <td className="emptyrow"></td>
                                <td className="emptyrow text-center">
                                  <strong>Total</strong>
                                </td>
                                <td className="emptyrow text-right">
                                  {FinaliseTotal(
                                    order.saleGBPTotal,
                                    deliveryCharge,
                                    order.discountApplied
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12"
                    style={{ marginBottom: "15px", textAlign: "center" }}
                  >
                    <div style={{ fontSize: "17px" }}>
                      <h4>Contact Us</h4>
                      <hr />
                    </div>

                    <div className="row">
                      <table className="table" style={{ textAlign: "center" }}>
                        <tr>
                          <td>
                            <b>Mark Andy Europe</b>
                            <br />
                            Queens Avenue
                            <br />
                            Hurdsfield Industrial Estate
                            <br />
                            Macclesfield, <br />
                            Cheshire SK10 2BN
                            <br />
                            England
                            <br />
                            Phone +44 1 625 500 964
                            <br />
                            Fax +44 1 625 501 334
                            <br />
                            <a href="mailto:euspares@markandy.com">
                              euspares@markandy.com
                            </a>{" "}
                            <br />
                            <br />
                            <br />
                          </td>

                          <td>
                            <b>Mark Andy Inc.</b>
                            <br />
                            18081 Chesterfield <br />
                            Airport Road
                            <br />
                            Chesterfield
                            <br />
                            USA
                            <br />
                            MO 63005
                            <br />
                            Tel - 001 636 532 4433
                            <br />
                            USA Toll Free 800 700 6275
                            <br />
                            <a href="mailto:parts@yourfriendinflexo.com">
                              parts@yourfriendinflexo.com
                            </a>
                          </td>
                        </tr>
                      </table>

                      <div className="col-6 col-md-6 col-lg-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default PDFOrder;
