import React, { ReactElement, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import Loader from "../../Loader";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function SubCategories(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const [cats, setCats] = useState<any | null>(null);

  const [subCats, setSubCats] = useState<any | null>(null);

  const apiService = new ApiService();

  const [isError, setIsError] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchSubCats = async (id: string) => {
    setLoading(true);
    const response = await apiService.getSubCategoriesByCategoryID(id);
    setSubCats(response);
    setLoading(false);
  };

  const handleInputChange = (index: any, event: any, subID: any) => {
    const values: any = [...subCats];

    const updatedValue = event.target.name;

    values[index][updatedValue] = event.target.value;
    values[index]["subID"] = subID;
    setSubCats(values);
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    PostData(subCats);
  };

  const PostData = async (data: any) => {
    const response = await apiService.PostData(
      "api/SubCategory/SaveSubCategories",
      data
    );

    if (response === 1) {
      setIsError(1);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
      fetchSubCats(String(id));
    } else {
      setIsError(2);
      setTimeout(() => setIsError(0), 3000);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubCats(String(id));
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Sub Categories</h1>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Category</th>
                        <th scope="col">Show</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subCats &&
                        subCats.map((item: any, index: number) => (
                          <tr>
                            <td>{item.subID}</td>
                            <td>
                              <input
                                type="text"
                                name="subName"
                                defaultValue={item.subName}
                                onChange={(event: any) =>
                                  handleInputChange(index, event, item.subID)
                                }
                              />
                            </td>
                            <td>
                              <div className="form-check">
                                <select
                                  className="form-select"
                                  id="country"
                                  name="show"
                                  onChange={(event: any) =>
                                    handleInputChange(index, event, item.subID)
                                  }
                                >
                                  <option value="1" selected={1 === item.show}>
                                    Live
                                  </option>
                                  <option value="3" selected={3 === item.show}>
                                    Archived
                                  </option>
                                </select>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <button
                    type="submit"
                    className="btn btn-fill-out btn-block hover-up"
                    name="login"
                  >
                    Save Sub Categories
                  </button>
                  <Link
                    style={{ textDecoration: "none", marginLeft: "10px" }}
                    to={`../Administration/SubCategories/Add-SubCategory?id=${id}`}
                    className="btn btn-fill-out btn-block hover-up"
                  >
                    Add new Sub Category
                  </Link>
                  <span
                    className={`badge badge-pill badge ${
                      isError === 2 ? "red" : isError === 1 ? "green" : ""
                    } `}
                  >
                    {isError === 2 ? "Error" : isError === 1 ? "Saved" : ""}
                  </span>
                </form>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
export default SubCategories;
