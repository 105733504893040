import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { AuthContext } from "../../context/authContext";
import Cookie from "js-cookie";
import Loader from "../Loader";

function DeliveryAddresses(): ReactElement {
  const { userData } = useContext(AuthContext);
  const cookieRetrieve = Cookie.get("token");
  const [loading, setLoading] = useState(false);

  const id = userData.userID;
  const apiService = new ApiService();

  const [deliveryAddresses, setDeliveryAddresses] = useState([] as any);

  const fetchDeliveryAddresses = async (id: string) => {
    setLoading(true);
    const response = await apiService.getDeliveryAddressesByUserID(id);
    setDeliveryAddresses(response);
    setLoading(false);
  };

  useEffect(() => {
    //fetchAllCountries();
    if (userData.userGuid) {
      fetchDeliveryAddresses(userData.userGuid);
    }
  }, [userData.userGuid]);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Delivery Addresses Created
          </div>
        </div>
      </div>
      <section className="pt-10 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="col-lg-12">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3 className="mb-30">Delivery Addresses</h3>

                      {loading ? (
                        <Loader></Loader>
                      ) : (
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              {/* <th scope="col">ID</th> */}
                              <th scope="col">Contact Name</th>
                              <th scope="col">Address Line 1</th>
                              <th scope="col">Address Line 2</th>
                              <th scope="col">Region</th>
                              <th scope="col">Postcode/Zip</th>
                              <th scope="col">Country</th>
                              <th scope="col">Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {deliveryAddresses &&
                              deliveryAddresses.map(
                                (item: any, index: number) => (
                                  <tr>
                                    {/* <td>{item.deliveryID}</td> */}
                                    <td>{item.contactName}</td>
                                    <td>{item.deliveryBuildingNo}</td>
                                    <td>{item.deliveryStreet}</td>
                                    <td>{item.deliveryRegion}</td>
                                    <td>{item.deliveryPostalcode}</td>
                                    <td>{item.country.name}</td>
                                    <td>
                                      {" "}
                                      <Link
                                        style={{ textDecoration: "none" }}
                                        state={{ id: item.deliveryID }}
                                        to={`../Delivery-Address-Edit`}
                                      >
                                        Edit
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      )}
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default DeliveryAddresses;
